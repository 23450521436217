import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

import decode from "../../../../general/util/jwtDecode";

export default function EditGroupDialog({ open, onClose, group, onEditGroup }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const currentUser = decode();

  useEffect(() => {
    if (group) {
      setName(group.name);
      setDescription(group.description);
    }
  }, [group]);

  const handleEdit = () => {
    onEditGroup(name, description);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Group</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Group Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Description"
          type="text"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleEdit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
