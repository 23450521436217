import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Typography,
  Box,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

export default function EditProductGroupDialog({
  open,
  onClose,
  group,
  onEditProductGroup,
}) {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const currentUser = decode();

  useEffect(() => {
    if (group) {
      setSelectedProducts(group.products.map((product) => product.id));
    }
  }, [group]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await customFetch(
          `/v1/tenantadmin/${currentUser.tenant_id}/product-catalog`,
          { method: "GET" }
        );
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProducts();
  }, [currentUser.tenant_id]);

  const handleEdit = async () => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${group.id}/products`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ product_ids: selectedProducts }),
        }
      );
      onEditProductGroup(selectedProducts);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggleProduct = (productId) => {
    const currentIndex = selectedProducts.indexOf(productId);
    const newSelectedProducts = [...selectedProducts];

    if (currentIndex === -1) {
      newSelectedProducts.push(productId);
    } else {
      newSelectedProducts.splice(currentIndex, 1);
    }

    setSelectedProducts(newSelectedProducts);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Product Group</DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <Typography variant="h6">Assign Products</Typography>
          <List>
            {products.map((product) => (
              <ListItem
                key={product.id}
                button
                onClick={() => handleToggleProduct(product.id)}
              >
                <ListItemText
                  primary={product.name}
                  secondary={product.description}
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={() => handleToggleProduct(product.id)}
                    checked={selectedProducts.indexOf(product.id) !== -1}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleEdit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
