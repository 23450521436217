import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
  Chip,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Box,
} from "@mui/material";
import { statesList } from "../../../../general/util/statesList";
import moment from "moment-timezone";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

const EditSchedule = ({ openEdit, handleEditClose, fetchSchedules, products, selectedProduct, selectedSchedule, setSelectedSchedule, usedStates }) => {
  const currentUser = decode();
  const usTimezones = moment.tz.names().filter(tz => tz.startsWith("US/")).map(tz => {
    const offset = moment.tz(tz).format("Z");
    return { label: `${tz.replace("US/", "")} (UTC${offset})`, value: tz };
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedSchedule(prevSchedule => ({ ...prevSchedule, [name]: value }));
  };

  const handleEditSave = async () => {
    if (
      !selectedSchedule.name ||
      !selectedSchedule.description ||
      !selectedSchedule.start_time ||
      !selectedSchedule.end_time ||
      !selectedSchedule.states.length ||
      !selectedSchedule.timezone ||
      !selectedSchedule.active_days.length
    ) {
      alert("All fields are required.");
      return;
    }

    try {
      const response = await customFetch(`/v1/users/${currentUser.tenant_user_id}/products/${products[selectedProduct].id}/schedules/${selectedSchedule.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(selectedSchedule),
      });
      if (response.ok) {
        fetchSchedules(products[selectedProduct].id);
        handleEditClose();
      } else {
        const errorData = await response.json();
        console.error("Error editing schedule", errorData);
      }
    } catch (error) {
      console.error("Error editing schedule", error);
    }
  };

  return (
    <Dialog open={openEdit} onClose={handleEditClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Schedule</DialogTitle>
      <DialogContent>
        {selectedSchedule && (
          <>
            <TextField autoFocus margin="dense" name="name" label="Name" type="text" fullWidth value={selectedSchedule.name} onChange={handleInputChange} />
            <TextField margin="dense" name="description" label="Description" type="text" fullWidth value={selectedSchedule.description} onChange={handleInputChange} />
            <Box display="flex" justifyContent="space-between">
              <TextField margin="dense" name="start_time" label="Start Time" type="time" value={selectedSchedule.start_time} onChange={handleInputChange} fullWidth sx={{ flex: 1, marginRight: 1 }} />
              <TextField margin="dense" name="end_time" label="End Time" type="time" value={selectedSchedule.end_time} onChange={handleInputChange} fullWidth sx={{ flex: 1, marginRight: 1 }} />
              <Autocomplete
                options={usTimezones}
                getOptionLabel={(option) => option.label}
                value={usTimezones.find(tz => tz.value === selectedSchedule.timezone)}
                onChange={(event, newValue) => setSelectedSchedule(prevSchedule => ({ ...prevSchedule, timezone: newValue?.value || "" }))}
                renderInput={(params) => <TextField {...params} label="Timezone" margin="dense" />}
                fullWidth sx={{ flex: 1 }}
              />
            </Box>
            <Autocomplete
              multiple
              options={statesList}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              value={statesList.filter(state => selectedSchedule.states.includes(state.value))}
              onChange={(event, newValue) => setSelectedSchedule(prevSchedule => ({ ...prevSchedule, states: newValue.map(state => state.value) }))}
              renderOption={(props, option, { selected }) => {
                const isDisabled = usedStates.includes(option.value) && !selectedSchedule.states.includes(option.value);
                return (
                  <Tooltip title={isDisabled ? "State is already used in another schedule" : ""} arrow>
                    <span>
                      <li
                        {...props}
                        style={{
                          backgroundColor: isDisabled ? "rgba(0, 0, 0, 0.08)" : "inherit",
                          color: isDisabled ? "rgba(0, 0, 0, 0.38)" : "inherit",
                          pointerEvents: isDisabled ? "none" : "auto",
                        }}
                      >
                        <Checkbox icon={<span />} checkedIcon={<span />} style={{ marginRight: 8 }} checked={selected} disabled={isDisabled} />
                        {option.label}
                      </li>
                    </span>
                  </Tooltip>
                );
              }}
              renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => <Chip key={index} label={option.label} {...getTagProps({ index })} />)}
              renderInput={(params) => <TextField {...params} label="States" margin="dense" fullWidth />}
            />
            <Autocomplete
              multiple
              options={["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              value={selectedSchedule.active_days}
              onChange={(event, newValue) => setSelectedSchedule(prevSchedule => ({ ...prevSchedule, active_days: newValue }))}
              renderInput={(params) => <TextField {...params} label="Active Days" margin="dense" fullWidth />}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <FormControlLabel control={<Checkbox checked={selectedSchedule?.is_active || false} onChange={(e) => setSelectedSchedule(prevSchedule => ({ ...prevSchedule, is_active: e.target.checked }))} color="primary" />} label="Active" />
        <Button onClick={handleEditClose} color="secondary">Cancel</Button>
        <Button onClick={handleEditSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSchedule;
