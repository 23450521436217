import React, { useState } from "react";
import {
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import BannerLogo from "../layout/BannerLogo";

export default function Login() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const theme = useTheme();

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError("");
      await login();
      setShowAlert(true);
    } catch (error) {
      setError("Failed to authenticate");
    }
    setLoading(false);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShowAlert(false);
    setError("");
  };

  const login = async () => {
    const body = {
      email: email,
    };

    const response = await fetch(`/v1/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });
    const data = await response.json();

    if (response.status === 500) {
      setError(
        "Error logging in... Please try again later or contact support."
      );
      console.log(data);
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "100%", maxWidth: 400 }}>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 1,
              }}
            >
              <BannerLogo />
            </Box>
            {/* <Typography variant="h4" align="center" gutterBottom>
              Log In
            </Typography> */}
            {error ? (
              <>
                <Alert severity="error">{error}</Alert>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleClose}
                  sx={{ mt: 2 }}
                >
                  Close
                </Button>
              </>
            ) : showAlert ? (
              <>
                <Alert severity="success">
                  If an account exists with this email, you will receive a link
                  to log in at {email}
                </Alert>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleClose}
                  sx={{ mt: 2 }}
                >
                  Close
                </Button>
              </>
            ) : (
              <>
                <Box component="form" onSubmit={handleSubmit} noValidate>
                  <TextField
                    fullWidth
                    id="email"
                    label="Enter your email to log in"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                    margin="normal"
                    variant="outlined"
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    sx={{ mt: 2 }}
                  >
                    {loading ? <CircularProgress size={24} /> : "Log In"}
                  </Button>
                </Box>
              </>
            )}
          </CardContent>
        </Card>
        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          If this is your first purchase <a href="/signup">get started here.</a>
        </Typography>
      </Box>
      {/* <Card
        sx={{
          width: 300,
          backgroundColor: "primary.main",
          color: "white",
          padding: 2,
          mt: 4,
        }}
      >
        <CardContent>
          <Typography variant="h6">Special Sale!</Typography>
          <Typography variant="body2">
            Orders will receive <b>10% extra leads</b> upon completion!
          </Typography>
        </CardContent>
      </Card> */}
    </Container>
  );
}
