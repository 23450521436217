import { createTheme } from "@mui/material/styles";

export const getTheme = (mode) => {
  return createTheme({
    typography: {
      fontFamily: "Montserrat, Arial, sans-serif",
    },
    palette: {
      mode,
      ...(mode === "light"
        ? {
            // Light mode colors
            primary: {
              main: "#11053C",
            },
            secondary: {
              main: "#29DDFC",
            },
            // error: {
            //   main: "#E84E38",
            // },
            // info: {
            //   main: "#A530FA",
            // },
            // success: {
            //   main: "#506348",
            // },
          }
        : {
            // Dark mode colors
            primary: {
              main: "#A530FA",
            },
            secondary: {
              main: "#29DDFC",
            },
            // error: {
            //   main: "#E84E38",
            // },
            // info: {
            //   main: "#3E516D",
            // },
            // success: {
            //   main: "#506348",
            // },
          }),
    },
  });
};

export default getTheme;
