import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Alert,
  Autocomplete,
  TextField,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import BannerLogo from "../layout/BannerLogo";
import decode from "../util/jwtDecode";

export default function Auth() {
  const [isAuth, setIsAuth] = useState(true);
  const [tenants, setTenants] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const theme = useTheme();

  const getCodeFromURL = async () => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get("code");
      const response = await fetch(`/v1/auth/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code: code }),
      });

      if (response.status === 403) {
        setIsAuth(false);
        setError("Unable to authenticate. Please try again or contact support");
        setLoading(false);
        return;
      }

      const data = await response.json();

      if (data.tenants) {
        setTenants(data.tenants);
      } else {
        handleAuthentication(data);
      }
      setLoading(false);
    } catch (error) {
      setError("An error occurred during authentication. Please try again.");
      setLoading(false);
    }
  };

  const handleAuthentication = (data) => {
    document.cookie = `accessToken=${data.token}; path=/; secure; SameSite=Lax; maxAge=3600;`;
    document.cookie = `refreshToken=${data.refresh}; path=/; secure; SameSite=Lax;`;
    const currentUser = decode();
    if (currentUser && currentUser.role === "Tenant Admin") {
      window.location = `/admin`;
    } else if (currentUser) {
      window.location = `/`;
    } else {
      window.location = `/login`;
    }
  };

  const handleTenantSelection = async () => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get("code");
      const response = await fetch(`/v1/auth/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code: code, tenant_id: selectedTenant }),
      });

      if (response.status === 403) {
        setIsAuth(false);
        setError("Unable to authenticate. Please try again or contact support");
        return;
      }

      const data = await response.json();
      handleAuthentication(data);
    } catch (error) {
      setError("An error occurred during tenant selection. Please try again.");
    }
  };

  useEffect(() => {
    getCodeFromURL();
  }, []);

  if (loading) {
    return (
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (tenants.length > 1) {
    const groupedTenants = tenants.reduce((acc, tenant) => {
      const { partner_name } = tenant;
      if (!acc[partner_name]) {
        acc[partner_name] = [];
      }
      acc[partner_name].push(tenant);
      return acc;
    }, {});

    return (
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        }}
      >
        <Box sx={{ width: "100%", maxWidth: 400 }}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 1,
                }}
              >
                <BannerLogo />
              </Box>
              <Typography variant="h4" align="center" gutterBottom>
                Select Your Tenant
              </Typography>
              {error && <Alert severity="error">{error}</Alert>}
              {Object.keys(groupedTenants).map((partnerName) => (
                <Box key={partnerName} sx={{ mt: 2 }}>
                  <Typography variant="h6" gutterBottom>
                  {partnerName}
                  </Typography>
                  {groupedTenants[partnerName].map((tenant) => (
                    <Box key={tenant.tenant_id} sx={{ mb: 1 }}>
                      <Button
                        fullWidth
                        variant={
                          selectedTenant === tenant.tenant_id
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => setSelectedTenant(tenant.tenant_id)}
                      >
                        {tenant.tenant_name} - ({tenant.role_name})
                      </Button>
                    </Box>
                  ))}
                  <Divider sx={{ my: 2 }} />
                </Box>
              ))}
              <Button
                fullWidth
                variant="contained"
                onClick={handleTenantSelection}
                disabled={!selectedTenant}
                sx={{ mt: 2 }}
              >
                Continue
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Container>
    );
  }

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Box sx={{ width: "100%", maxWidth: 400 }}>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 1,
              }}
            >
              <BannerLogo />
            </Box>
            <Typography variant="h4" align="center" gutterBottom>
              {isAuth ? "Authenticating User" : "Unable to Authenticate"}
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
