import React, { useState, useEffect, useCallback } from "react";
import CreateProductLead from "./components/CreateProductLead";
import EditLead from "./components/EditLead";
import DeleteLead from "./components/DeleteLead";
import decode from "../../../general/util/jwtDecode";
import {
  Typography,
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Container,
} from "@mui/material";
import {
  DataGridPro,
  getGridDateOperators,
  getGridNumericOperators,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import RepushLead from "./components/RepushLead";
import { LicenseInfo } from "@mui/x-license";
import CustomNoRowsOverlay from "../../../general/layout/CustomNoRowsOverlay";
import customFetch from "../../../general/auth/customFetch";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export default function AdminLeads() {
  const currentUser = decode();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState({});
  const [productsList, setProductsList] = useState([]);
  const [states, setStates] = useState([]);

  const dateOperators = getGridDateOperators().filter((operator) =>
    ["is", "after", "onOrAfter", "before", "onOrBefore"].includes(
      operator.value
    )
  );

  const updateRow = (updatedLead) => {
    const parsedLead = {
      ...updatedLead,
      product_type: getProductName(updatedLead.product_id),
      created: updatedLead.created
        ? parseISODateString(updatedLead.created)
        : null,
      dob: updatedLead.dob ? parseISODateStringNoTimezone(updatedLead.dob) : null,
    };

    setRows((prevRows) =>
      prevRows.map((row) => {
        if (row.id === parsedLead.id) {
          return parsedLead;
        }
        return row;
      })
    );
  };

  const onFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
  };

  const onSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  const fetchProductsAsync = useCallback(async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/product-catalog`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setProductsList(data);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  }, [currentUser.tenant_id]);

  const fetchStatesAsync = useCallback(async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/orders/running-states`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setStates(data);
    } catch (error) {
      console.error("Failed to fetch states:", error);
    }
  }, [currentUser.tenant_id]);

  useEffect(() => {
    fetchStatesAsync();
  }, [fetchStatesAsync]);

  function parseISODateString(isoString) {
    const date = new Date(isoString);
    return isNaN(date.getTime()) ? null : date; // Check if date is invalid
  }

  function parseISODateStringNoTimezone(isoString) {
    if (!isoString) return null;
    
    // Remove the trailing 'Z' to avoid UTC conversion
    const localISOString = isoString.replace('Z', '');
    
    // Create a Date object assuming the time is in the local timezone
    const date = new Date(localISOString);
    
    return isNaN(date.getTime()) ? null : date;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const customNumberOperators = getGridNumericOperators()
    .concat([
      {
        label: "Unassigned",
        value: "isNull",
        headerLabel: "Unassigned",
        getApplyFilterFn: function (filterItem) {
          return function (params) {
            return (
              params.value === null ||
              params.value === undefined ||
              params.value === ""
            );
          };
        },
      },
      {
        label: "Pending Bulk Assign",
        value: "isOne", // You can choose an appropriate value name
        headerLabel: "Pending Bulk Assign",
        getApplyFilterFn: function (filterItem) {
          return function (params) {
            return params.value === 1;
          };
        },
      },
    ])
    .filter(
      (operator) =>
        operator.value !== "isEmpty" && operator.value !== "isNotEmpty"
    );

  const getProductName = useCallback(
    (productId) => {
      const product = productsList.find((p) => p.id === productId);
      return product ? product.name : "";
    },
    [productsList]
  );

  useEffect(() => {
    const fetchLeads = async () => {
      setLoading(true);

      if (paginationModel.pageSize === 0) {
        paginationModel.pageSize = 10; // Reset pageSize to default if it is 0
      }

      if (paginationModel.pageSize === -1) {
        paginationModel.pageSize = 10; // Reset pageSize to default if it is 0
      }

      const params = new URLSearchParams({
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
      });

      if (sortModel[0]?.field && sortModel[0]?.sort) {
        params.append("sortField", sortModel[0].field);
        params.append("sortOrder", sortModel[0].sort);
      }

      if (filterModel.items) {
        filterModel.items.forEach((filter) => {
          if (filter.operator === "isNull" || filter.operator === "isOne") {
            console.log("found null");
            params.append(`filter_${filter.field}_value`, true);
            params.append(`filter_${filter.field}_operator`, filter.operator);
          } else if (filter.value !== undefined) {
            if (filter.field === "created" && filter.value instanceof Date) {
              filter.value = filter.value.toISOString().split("T")[0]; // Sends only the date part, YYYY-MM-DD
            }

            params.append(`filter_${filter.field}_value`, filter.value);
            params.append(
              `filter_${filter.field}_operator`,
              filter.operator || "equals"
            ); // Defaulting to 'equals'
          }
        });
      }

      try {
        const response = await customFetch(
          `/v2/tenantadmin/${currentUser.tenant_id}/leads?${params}`,
          {
            method: "GET",
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const processedData = data.leads.map((item) => {
          return {
            ...item,
            created: item.created ? parseISODateString(item.created) : null,
            dob: item.dob ? parseISODateStringNoTimezone(item.dob) : null,
            product_type: getProductName(item.product_id),
          };
        });
        setRows(processedData);
        setTotalCount(data.total);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLeads();
  }, [
    sortModel,
    filterModel,
    paginationModel,
    fetchProductsAsync,
    currentUser.tenant_id,
    getProductName,
  ]);

  useEffect(() => {
    if (currentUser) {
      fetchProductsAsync();
    }
  }, []);

  const columns = [
    {
      field: "ref_order",
      align: "left",
      headerAlign: "left",
      headerName: "Order ID",
      width: 50,
      flex: 1,
      type: "number",
      filterOperators: customNumberOperators,
      renderCell: (params) => (
        <>
          {params.row.ref_order ? (
            params.row.ref_order === 1 ? (
              <p>Pending Bulk Assign</p>
            ) : (
              params.row.ref_order
            )
          ) : (
            <>
              <p>Unassigned</p>
              {params.row.recommended && params.row.recommended.label}
            </>
          )}
        </>
      ),
    },
    {
      field: "first_name",
      headerName: "Agent Name",
      width: 230,
      flex: 1,
      renderCell: (params) =>
        params.row.first_name + " " + params.row.last_name,
    },
    { field: "name", headerName: "Lead Name", width: 230, flex: 1 },
    {
      field: "created",
      headerName: "Created Date",
      type: "date",
      width: 190,
      flex: 1,
      filterOperators: dateOperators,
    },
    { field: "state", headerName: "State", width: 130, flex: 1 },
    {
      field: "product_type",
      headerName: "Product Name",
      width: 130,
      flex: 1,
    },
    { field: "email", headerName: "Email", flex: 0.5 },
    { field: "phone", headerName: "Phone", flex: 0.5 },
    { field: "dob", headerName: "Dob", type: "date", flex: 0.5 },
    { field: "city", headerName: "City", flex: 0.5 },
    { field: "zip", headerName: "Zip", flex: 0.5 },
    {
      field: "custom_fields",
      headerName: "Custom Fields",
      flex: 1,
      renderCell: (params) =>
        params.row.custom_fields &&
        Object.entries(params.row.custom_fields).map(([key, value]) => (
          <div key={key}>
            <strong>{key}:</strong> {value}
          </div>
        )),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 160,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <EditLead
            lead={params.row}
            productsList={productsList}
            updateRow={updateRow}
          />
          <span> </span>
          <DeleteLead lead={params.row} />
          {params.row.crm_id === null && params.row.ref_order !== null && (
            <>
              <span> </span>
              <RepushLead lead={params.row} updateRow={updateRow} />
            </>
          )}
        </>
      ),
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    phone: false,
    dob: false,
    city: false,
    zip: false,
    custom_fields: true,
    email: false,
  });

  return (
    <div className="content">
      <Container
        maxWidth={false}
        disableGutters
        sx={{ width: "100vp", padding: 0, margin: 0 }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
            <Typography
              variant="h2"
              component="h2"
              gutterBottom
              sx={{
                color: "primary.main",
                marginBottom: 2,
              }}
            >
              Leads
            </Typography>
            <Box sx={{ marginBottom: 2 }}>
              <CreateProductLead productsList={productsList} />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
            <Card
              sx={{
                minWidth: 300,
                maxWidth: 700,
                padding: 0,
                display: "inline-block",
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Active States
                </Typography>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  wrap="wrap"
                  sx={{ maxHeight: 64 }}
                >
                  {states.map((state, index) => (
                    <Grid item key={index}>
                      <Chip
                        key={state.state}
                        label={state.state}
                        color={
                          state.status === "inactive" ? "error" : "default"
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <br />
      <br />
      <div style={{ width: "100%" }}>
        <DataGridPro
          rows={rows}
          loading={loading}
          columns={columns}
          onPaginationModelChange={setPaginationModel}
          rowCount={totalCount}
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          onSortModelChange={onSortModelChange}
          onFilterModelChange={onFilterModelChange}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          headerFilters
          pageSizeOptions={[10, 25, 50]}
          autoPageSize
          slots={{
            toolbar: CustomToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
            headerFilterMenu: null,
          }}
          pagination
        />
      </div>
    </div>
  );
}
