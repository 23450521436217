import React, { useState, useEffect } from "react";
import { Box, Button, Card, Typography, Avatar } from "@mui/material";
import decode from "../../../general/util/jwtDecode";
import customFetch from "../../../general/auth/customFetch";
import BannerLogo from "../../../general/layout/BannerLogo";
import LeadsBarChart from "../../../general/charts/LeadsBarChart";
import TopStatesDeliveredChart from "../../../general/charts/TopStatesDeliveredChart";
import DoublePieChart from "../../../general/charts/DoublePieChart";
import { LoomEmbed } from "../../../general/constants/LoomEmbeds";

export default function AgentDashboard() {
  const currentUser = decode();
  const [hasOrdered, setHasOrdered] = useState(false);

  const getAgentOrders = async () => {
    try {
      const response = await customFetch(`/v1/orders`, {
        method: "GET",
      });
      const data = await response.json();
      data.length > 0 && setHasOrdered(true);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (currentUser && !currentUser.id) {
      window.location = "/login";
    }
    getAgentOrders();
  }, []);

  return (
    <div className="content">
      <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 1 }}>
        <BannerLogo width="650" />
      </Box>
      <Typography variant="h5" textAlign="center" mt={0} pt={0}>
        powered by Lead2Client CRM
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 4,
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Avatar sx={{ width: 56, height: 56, marginRight: 2 }}>
          {currentUser.firstName.charAt(0) || "A"}
        </Avatar>
        <Typography variant="h6" mt={2}>
          Welcome, {currentUser.firstName || "Agent"}.
        </Typography>
      </Box>
      {currentUser.onboarded ? (
        <Box
          display="flex"
          flexWrap="wrap"
          gap={2}
          justifyContent="center"
          sx={{
            "& > .dashboard-item": {
              flex: "1 1 calc(50% - 16px)",
              minWidth: "500px",
              maxWidth: "calc(50% - 16px)",
              "@media (max-width: 1023px)": {
                flex: "1 1 100%",
                maxWidth: "100%",
              },
            },
          }}
        >
          <Box className="dashboard-item">
            <LoomEmbed />
          </Box>
          <Box className="dashboard-item">
            <TopStatesDeliveredChart />
          </Box>
          <Box className="dashboard-item">
            <LeadsBarChart />
          </Box>
          <Box className="dashboard-item">
            <DoublePieChart />
          </Box>
        </Box>
      ) : !currentUser.onboarded && !hasOrdered ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "calc(50vh - 64px)", // Adjust this value based on the height of your nav and app bars
            textAlign: "center",
          }}
        >
          <Typography variant="h6" textAlign="center" mb={2}>
            It looks like you haven't made an order yet, head over to the orders
            page to Get More Leads!
          </Typography>
          <Button variant="contained" href="/orders">
            Take me there
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h5" mt={5}>
            Thank You for Your Recent Purchase!
          </Typography>
          <Typography mt={1}>
            You will be able to manage your order and begin receiving leads
            after you complete a{" "}
            <strong>Business in a Box Onboarding Call</strong>.
            <br /> These onboardings are separate from the CRM onboarding as we
            provide different automation that works your leads when purchasing
            from BIAB.
          </Typography>

          <Typography variant="h5" mt={5} mb={0}>
            Schedule Your Onboarding If You Haven't Yet
          </Typography>
          <iframe
            src="https://ln.l2c.in/widget/booking/aQLTYrXpcDmhdQx9ydPe"
            style={{
              width: "90%",
              border: "none",
              overflow: "hidden",
              height: "970px",
            }}
            scrolling="yes"
            id="DUShFcrllPdKEv3I356f_1682799052986"
          ></iframe>
        </Box>
      )}
    </div>
  );
}
