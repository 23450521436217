import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  IconButton,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";

const CustomFieldsPage = () => {
  const [customFields, setCustomFields] = useState([]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formValues, setFormValues] = useState({
    name: "",
    field_key: "",
  });

  const currentUser = decode();

  useEffect(() => {
    loadCustomFields();
  }, []);

  const loadCustomFields = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/customFields`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch custom fields");
      }
      const data = await response.json();
      setCustomFields(data);
    } catch (error) {
      console.error("Failed to load custom fields:", error);
    }
  };

  const handleOpen = (field = null) => {
    if (field) {
      setEditMode(true);
      setSelectedField(field);
      setFormValues({
        name: field.name,
        field_key: field.field_key,
      });
    } else {
      setEditMode(false);
      setSelectedField(null);
      setFormValues({
        name: "",
        field_key: "",
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/customFields/${id}`,
        { method: "DELETE" }
      );
      if (!response.ok) {
        throw new Error("Failed to delete custom field");
      }
      setSnackbarMessage("Custom field deleted successfully");
      loadCustomFields();
    } catch (error) {
      console.error("Failed to delete custom field:", error);
      setSnackbarMessage("Failed to delete custom field");
    }
  };

  const handleSubmit = async () => {
    const method = editMode ? "PUT" : "POST";
    const url = editMode
      ? `/v1/tenantadmin/${currentUser.tenant_id}/customFields/${selectedField.id}`
      : `/v1/tenantadmin/${currentUser.tenant_id}/customFields`;

    const body = {
      name: formValues.name,
      field_key: formValues.field_key,
    };

    try {
      const response = await customFetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        throw new Error("Failed to save custom field");
      }
      setSnackbarMessage(
        editMode
          ? "Custom field updated successfully"
          : "Custom field created successfully"
      );
      handleClose();
      loadCustomFields();
    } catch (error) {
      console.error("Failed to save custom field:", error);
      setSnackbarMessage("Failed to save custom field");
    }
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1.2 },
    { field: "field_key", headerName: "Field Key", flex: 1.2 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleOpen(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Container>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main", // Use the primary color from the theme
          marginBottom: 2, // Theme spacing unit
          textAlign: "left", // left align text
        }}
      >
        Manage Custom Fields
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpen()}
        sx={{ marginBottom: 2 }}
      >
        Add Custom Field
      </Button>
      <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
        <DataGridPro
          rows={customFields}
          columns={columns}
          pageSize={10}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          headerFilters
          pageSizeOptions={[10, 25, 50]}
          pagination
          autoHeight
        />
      </div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          {editMode ? "Edit Custom Field" : "Add Custom Field"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={formValues.name}
            onChange={(e) =>
              setFormValues({ ...formValues, name: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Field Key"
            type="text"
            fullWidth
            value={formValues.field_key}
            onChange={(e) =>
              setFormValues({ ...formValues, field_key: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage("")}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default CustomFieldsPage;
