import React from "react";
import { useTheme } from "@mui/material/styles";

export default function BannerLogo({width}) {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";

  const aspectRatio = 228.64 / 46.45;
  const height = width / aspectRatio;


  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 228.64 46.45"
        width={`${width}px`}
        height={`${height}px`}
      >
        <defs>
          <style>
            {`.l{fill:url(#e);}
              .m{fill:url(#d);}
              .n{fill:url(#g);}
              .o{fill:url(#f);}
              .p{fill:url(#i);}
              .q{fill:url(#h);}
              .r{fill:url(#k);}
              .s{fill:url(#j);}
              .t{fill:${darkMode ? "#ffffff" : "#11053c"};}`}
          </style>
          <linearGradient
            id="d"
            x1="25.89"
            y1="17.5"
            x2="47.69"
            y2="17.5"
            gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#a530fa" />
            <stop offset="1" stopColor="#29ddfc" />
          </linearGradient>
          <linearGradient
            id="e"
            x1="37.37"
            y1="35.33"
            x2="71.64"
            y2="35.33"
            xlinkHref="#d"
          />
          <linearGradient
            id="f"
            x1="82.2"
            y1="35.82"
            x2="215.2"
            y2="14.16"
            xlinkHref="#d"
          />
          <linearGradient
            id="g"
            x1="82.69"
            y1="38.86"
            x2="215.7"
            y2="17.2"
            xlinkHref="#d"
          />
          <linearGradient
            id="h"
            x1="83.72"
            y1="45.14"
            x2="216.72"
            y2="23.48"
            xlinkHref="#d"
          />
          <linearGradient
            id="i"
            x1="84.41"
            y1="49.4"
            x2="217.42"
            y2="27.74"
            xlinkHref="#d"
          />
          <linearGradient
            id="j"
            x1="85.08"
            y1="53.55"
            x2="218.09"
            y2="31.89"
            xlinkHref="#d"
          />
          <linearGradient
            id="k"
            x1="85.73"
            y1="57.54"
            x2="218.74"
            y2="35.88"
            xlinkHref="#d"
          />
        </defs>
        <g id="a" />
        <g id="b">
          <g id="c">
            <g>
              <g>
                <path
                  className="m"
                  d="M47.69,.71c-3.74,.18-7.25,1.25-10.32,3.02-.53,.3-1.04,.63-1.54,.97-2.56,1.76-4.75,4.03-6.41,6.67-.3,.47-.58,.96-.85,1.46-1.71,3.2-2.68,6.86-2.68,10.73s.97,7.53,2.68,10.73c3.55-1.89,6.05-5.48,6.41-9.68-.03-.35-.04-.7-.04-1.05s.01-.71,.04-1.05c.1-1.34,.39-2.62,.85-3.82,1.19-3.14,3.48-5.74,6.41-7.32,.5-.27,1.01-.51,1.54-.71,1.23-.48,2.54-.78,3.9-.88V.71Z"
                />
                <path
                  className="l"
                  d="M62.59,24.21c-.33,7.33-6.4,13.19-13.82,13.19-1.76,0-3.44-.33-4.99-.93-1.67,2.7-3.85,5.06-6.41,6.93,3.36,1.94,7.25,3.05,11.4,3.05,12.41,0,22.53-9.92,22.87-22.24h-9.05Z"
                />
                <path
                  className="t"
                  d="M43.09,12.84c-3.55,1.89-6.05,5.48-6.41,9.68,.03,.35,.04,.7,.04,1.05s-.01,.71-.04,1.05c-.1,1.33-.39,2.62-.85,3.82-1.19,3.14-3.48,5.74-6.41,7.32-.5,.27-1.01,.51-1.54,.71-1.55,.6-3.23,.93-4.99,.93-7.63,0-13.83-6.2-13.83-13.83s6.21-13.83,13.83-13.83c1.76,0,3.44,.33,4.99,.93,1.67-2.7,3.85-5.06,6.41-6.93-3.36-1.94-7.25-3.05-11.4-3.05C10.27,.68,0,10.95,0,23.57s10.27,22.88,22.89,22.88c4.15,0,8.05-1.11,11.4-3.05,.53-.3,1.04-.63,1.54-.98,2.56-1.76,4.75-4.04,6.41-6.67,.3-.47,.58-.96,.85-1.46,1.71-3.2,2.68-6.86,2.68-10.73s-.97-7.53-2.68-10.73Z"
                />
              </g>
              <polygon
                className="t"
                points="62.64 23.06 71.66 23.06 71.66 .69 48.6 .69 48.6 9.71 62.64 9.71 62.64 23.06"
              />
              <g>
                <path
                  className="t"
                  d="M78.91,21.08V.18h9.02c2.33,0,4.14,.47,5.42,1.4s1.93,2.26,1.93,3.97c0,1.13-.26,2.09-.79,2.87s-1.24,1.36-2.15,1.76-1.9,.6-2.97,.6l.51-.72c1.33,0,2.48,.21,3.45,.61,.96,.41,1.72,1.02,2.26,1.82,.54,.81,.8,1.81,.8,3,0,1.77-.65,3.15-1.96,4.12-1.3,.98-3.27,1.46-5.9,1.46h-9.62Zm2.21-1.82h7.38c1.83,0,3.23-.31,4.21-.94,.97-.63,1.46-1.63,1.46-3s-.49-2.34-1.46-2.97c-.98-.63-2.38-.94-4.21-.94h-7.61v-1.82h6.9c1.67,0,2.97-.32,3.9-.96s1.39-1.58,1.39-2.84-.46-2.22-1.39-2.85-2.23-.94-3.9-.94h-6.66V19.26Z"
                />
                <path
                  className="t"
                  d="M109.85,21.26c-2.67,0-4.77-.77-6.3-2.3-1.53-1.53-2.3-3.8-2.3-6.81V.18h2.21V12.06c0,2.47,.56,4.29,1.67,5.46,1.11,1.18,2.69,1.76,4.72,1.76s3.63-.59,4.75-1.76c1.12-1.17,1.67-3,1.67-5.46V.18h2.15V12.15c0,3.01-.76,5.28-2.28,6.81-1.52,1.53-3.62,2.3-6.29,2.3Z"
                />
                <path
                  className="t"
                  d="M130.84,21.26c-1.55,0-3.04-.25-4.46-.76-1.42-.51-2.52-1.16-3.3-1.96l.87-1.7c.74,.72,1.73,1.32,2.97,1.81,1.24,.49,2.55,.73,3.92,.73s2.38-.16,3.21-.49c.83-.33,1.43-.77,1.82-1.33,.39-.56,.58-1.17,.58-1.85,0-.82-.24-1.47-.7-1.97-.47-.5-1.08-.89-1.84-1.18-.76-.29-1.59-.54-2.51-.76s-1.83-.45-2.75-.7c-.92-.25-1.76-.58-2.52-1s-1.38-.97-1.85-1.66c-.47-.69-.7-1.59-.7-2.7,0-1.04,.27-1.99,.82-2.85,.55-.87,1.39-1.56,2.53-2.09s2.59-.79,4.36-.79c1.17,0,2.34,.17,3.49,.49s2.15,.78,2.99,1.36l-.75,1.76c-.9-.6-1.85-1.04-2.85-1.31-1.01-.28-1.98-.42-2.91-.42-1.25,0-2.29,.17-3.11,.51-.82,.34-1.42,.79-1.81,1.36s-.58,1.21-.58,1.93c0,.82,.24,1.47,.7,1.97,.47,.5,1.09,.89,1.85,1.16,.77,.28,1.61,.53,2.52,.75,.92,.22,1.83,.46,2.73,.72,.9,.26,1.74,.59,2.51,1s1.38,.95,1.85,1.63c.47,.68,.7,1.56,.7,2.66,0,1.02-.28,1.96-.84,2.82-.56,.87-1.41,1.56-2.55,2.09s-2.61,.79-4.41,.79Z"
                />
                <path className="t" d="M143.44,21.08V.18h2.21V21.08h-2.21Z" />
                <path
                  className="t"
                  d="M152.46,21.08V.18h1.82l14.43,18.16h-.96V.18h2.18V21.08h-1.82l-14.39-18.16h.96V21.08h-2.21Z"
                />
                <path
                  className="t"
                  d="M178.95,19.17h12.57v1.91h-14.78V.18h14.33v1.91h-12.12V19.17Zm-.24-9.65h11.05v1.88h-11.05v-1.88Z"
                />
                <path
                  className="t"
                  d="M202.48,21.26c-1.55,0-3.04-.25-4.46-.76-1.42-.51-2.52-1.16-3.3-1.96l.87-1.7c.74,.72,1.73,1.32,2.97,1.81,1.24,.49,2.55,.73,3.92,.73s2.38-.16,3.21-.49c.83-.33,1.43-.77,1.82-1.33,.39-.56,.58-1.17,.58-1.85,0-.82-.24-1.47-.7-1.97-.47-.5-1.08-.89-1.84-1.18-.76-.29-1.59-.54-2.51-.76s-1.83-.45-2.75-.7c-.92-.25-1.76-.58-2.52-1s-1.38-.97-1.85-1.66c-.47-.69-.7-1.59-.7-2.7,0-1.04,.27-1.99,.82-2.85,.55-.87,1.39-1.56,2.53-2.09s2.59-.79,4.36-.79c1.17,0,2.34,.17,3.49,.49s2.15,.78,2.99,1.36l-.75,1.76c-.9-.6-1.85-1.04-2.85-1.31-1.01-.28-1.98-.42-2.91-.42-1.25,0-2.29,.17-3.11,.51-.82,.34-1.42,.79-1.81,1.36s-.58,1.21-.58,1.93c0,.82,.24,1.47,.7,1.97,.47,.5,1.09,.89,1.85,1.16,.77,.28,1.61,.53,2.52,.75,.92,.22,1.83,.46,2.73,.72,.9,.26,1.74,.59,2.51,1s1.38,.95,1.85,1.63c.47,.68,.7,1.56,.7,2.66,0,1.02-.28,1.96-.84,2.82-.56,.87-1.41,1.56-2.55,2.09s-2.61,.79-4.41,.79Z"
                />
                <path
                  className="t"
                  d="M220.84,21.26c-1.55,0-3.04-.25-4.46-.76-1.42-.51-2.52-1.16-3.3-1.96l.87-1.7c.74,.72,1.73,1.32,2.97,1.81,1.24,.49,2.55,.73,3.92,.73s2.38-.16,3.21-.49c.83-.33,1.43-.77,1.82-1.33,.39-.56,.58-1.17,.58-1.85,0-.82-.24-1.47-.7-1.97-.47-.5-1.08-.89-1.84-1.18-.76-.29-1.59-.54-2.51-.76s-1.83-.45-2.75-.7c-.92-.25-1.76-.58-2.52-1s-1.38-.97-1.85-1.66c-.47-.69-.7-1.59-.7-2.7,0-1.04,.27-1.99,.82-2.85,.55-.87,1.39-1.56,2.53-2.09s2.59-.79,4.36-.79c1.17,0,2.34,.17,3.49,.49s2.15,.78,2.99,1.36l-.75,1.76c-.9-.6-1.85-1.04-2.85-1.31-1.01-.28-1.98-.42-2.91-.42-1.25,0-2.29,.17-3.11,.51-.82,.34-1.42,.79-1.81,1.36s-.58,1.21-.58,1.93c0,.82,.24,1.47,.7,1.97,.47,.5,1.09,.89,1.85,1.16,.77,.28,1.61,.53,2.52,.75,.92,.22,1.83,.46,2.73,.72,.9,.26,1.74,.59,2.51,1s1.38,.95,1.85,1.63c.47,.68,.7,1.56,.7,2.66,0,1.02-.28,1.96-.84,2.82-.56,.87-1.41,1.56-2.55,2.09s-2.61,.79-4.41,.79Z"
                />
              </g>
              <g>
                <path
                  className="o"
                  d="M81.31,45.28c-.1,.19-.26,.31-.46,.36-.05,.02-.13,.03-.23,.03-.19,0-.36-.06-.51-.17-.15-.11-.25-.25-.28-.42-.03-.21-.05-.57-.05-1.08v-2c.02-1.88,.04-4.42,.06-7.62,.03-3.2,.06-5.56,.09-7.09-.02-.22,.06-.42,.22-.58s.36-.24,.6-.24c.22,0,.42,.08,.58,.23,.16,.15,.25,.34,.27,.56-.03,1.75-.07,4.19-.09,7.33-.03,3.14-.05,5.61-.07,7.41v2.65c.02,.24-.02,.45-.13,.64Z"
                />
                <path
                  className="n"
                  d="M108.66,45.71c-.17,0-.34-.07-.52-.21-6.06-5.66-11.79-11.09-17.2-16.28l-.02,7.81c0,4.19,0,6.79-.03,7.78,0,.24-.08,.44-.23,.6-.15,.16-.35,.24-.59,.22-.22,0-.42-.08-.59-.24s-.26-.35-.26-.58l.05-17.41c0-.22,.08-.42,.24-.59,.16-.17,.35-.26,.58-.26s.44,.09,.67,.26c2.52,2.38,4.75,4.49,6.71,6.34,1.96,1.85,3.47,3.27,4.52,4.26s1.83,1.72,2.32,2.18l3.57,3.36c0-6.97-.02-12.19-.05-15.69,0-.24,.09-.44,.26-.6,.17-.16,.37-.24,.59-.24s.42,.08,.58,.24,.24,.36,.24,.58c.02,2.07,.03,4.99,.04,8.74,0,3.76,.01,6.71,.01,8.84v.03c0,.24-.08,.44-.23,.6-.15,.16-.35,.24-.59,.24h-.08Z"
                />
                <path
                  className="q"
                  d="M144.83,45.64c-.14,0-.28-.04-.42-.12-.15-.08-.26-.18-.35-.3v-.03l-2.72-4.75-7.04-.03h-6.14l-2.72,4.78c-.07,.12-.17,.22-.31,.3-.14,.08-.27,.12-.41,.12-.16,0-.3-.04-.44-.13-.27-.17-.41-.39-.41-.67,0-.15,.04-.3,.13-.44l1.18-2.08c3.13-5.51,5.42-9.5,6.86-11.96l.33-.62c.6-1.09,1-1.81,1.21-2.13l.46-.8c.17-.27,.42-.41,.74-.41s.57,.15,.72,.46c.79,1.39,2.12,3.72,4.01,7.01,.62,1.06,1.65,2.86,3.09,5.39s2.42,4.26,2.91,5.19c.07,.12,.1,.26,.1,.41,0,.29-.14,.52-.41,.69-.12,.07-.25,.1-.38,.1Zm-5.67-8.99c-2.1-3.63-3.58-6.2-4.42-7.73l-1.26,2.18-4.36,7.68c1.18,.02,2.23,.03,3.15,.03h8.12l-1.23-2.16Z"
                />
                <path
                  className="p"
                  d="M177.35,38.61c.2,.62,.29,1.25,.29,1.89,0,1.08-.27,2.02-.81,2.82-.54,.8-1.3,1.4-2.27,1.8-.65,.26-1.43,.43-2.32,.51-.9,.09-2.15,.13-3.74,.13-1.45,0-2.63,0-3.54-.03-.43,0-.75,0-.95-.03l-3.24-.03c-.22,0-.41-.08-.58-.24s-.25-.36-.25-.6l.16-17.56,.05-.31c.14-.39,.4-.59,.79-.59l2.52-.03c.14-.02,.36-.03,.67-.03l3.42-.05c1.45,0,2.7,.08,3.75,.23,1.04,.15,2.01,.52,2.9,1.1,.65,.45,1.16,1.03,1.53,1.76,.37,.73,.55,1.49,.55,2.3,0,.39-.03,.74-.1,1.03-.19,.99-.66,1.81-1.41,2.46l-.08,.1c.57,.29,1.11,.76,1.62,1.41,.05,.05,.09,.1,.13,.15,.03,.05,.06,.09,.08,.1,.36,.5,.64,1.06,.83,1.68Zm-1.4,1.89c0-.46-.07-.92-.2-1.37-.14-.45-.34-.85-.59-1.19l-.16-.21c-.33-.43-.66-.74-1-.95s-.79-.36-1.33-.46c-.09-.02-.2-.03-.33-.05-.14-.02-.31-.03-.52-.05-.55-.03-1.24-.05-2.08-.05-1.11,0-2.76,.02-4.95,.05l-3.06,.05-.02,2.64-.03,2.67c-.02,.72-.02,1.53-.02,2.44l3.34,.05c.87,.02,2,.03,3.39,.03,1.52,0,2.7-.04,3.52-.12s1.5-.23,2.03-.45c1.14-.43,1.81-1.27,2-2.54,.01-.1,.02-.26,.02-.49Zm-8.86-5.98c2.59,0,4.18,0,4.8,.03,.69-.02,1.26-.21,1.73-.59s.78-.9,.91-1.57c.05-.26,.08-.52,.08-.8,0-.53-.12-1.02-.35-1.48-.23-.45-.56-.82-.99-1.09-.7-.48-1.51-.78-2.44-.9-.93-.12-2.1-.18-3.52-.18-.87,0-1.95,.03-3.23,.08-1.16,.04-1.93,.05-2.31,.05l-.05,6.44h5.37Z"
                />
                <path
                  className="s"
                  d="M184.32,42.58c-.77-.89-1.36-1.9-1.77-3.04s-.62-2.31-.62-3.53c0-1.35,.25-2.64,.76-3.86s1.23-2.31,2.17-3.25c1.01-.98,2.21-1.72,3.61-2.22,1.39-.5,2.85-.76,4.35-.76,1.64,0,3.22,.3,4.74,.9,1.51,.6,2.78,1.46,3.79,2.57,.84,.92,1.48,1.98,1.93,3.17,.44,1.19,.67,2.44,.67,3.74,0,1.45-.31,2.82-.94,4.08-.63,1.27-1.49,2.4-2.61,3.39-.89,.79-2.05,1.37-3.48,1.75s-2.89,.57-4.38,.57c-.77,0-1.46-.05-2.06-.15-2.55-.41-4.6-1.52-6.16-3.34Zm8.68,1.85c2.58,0,4.71-.65,6.37-1.95,.94-.75,1.66-1.67,2.17-2.75,.51-1.08,.76-2.25,.76-3.52,0-1.06-.19-2.09-.58-3.08-.38-.99-.92-1.86-1.6-2.59-.87-.96-1.96-1.69-3.25-2.2-1.29-.5-2.65-.76-4.07-.76-1.3,0-2.54,.21-3.74,.64-1.19,.43-2.19,1.05-3.02,1.87-.8,.79-1.42,1.7-1.84,2.73-.42,1.04-.63,2.11-.63,3.22,0,.99,.17,1.96,.51,2.89s.84,1.78,1.49,2.53c1.54,1.8,3.68,2.77,6.42,2.93h.05c.22,.02,.54,.03,.95,.03Z"
                />
                <path
                  className="r"
                  d="M208.46,45.61c-.16-.15-.24-.35-.24-.59v-.08c0-.17,.07-.34,.2-.51,2.26-2.36,4.99-5.17,8.19-8.42l-2.87-2.95-5.19-5.34c-.15-.15-.23-.36-.23-.62,0-.22,.09-.42,.26-.58,.17-.16,.38-.24,.61-.24,.24,.02,.43,.1,.57,.26,2.74,2.87,5.42,5.63,8.03,8.27l3.16-3.18,5.14-5.13c.15-.15,.35-.23,.59-.23s.44,.08,.59,.23c.15,.15,.23,.35,.23,.59,0,.26-.08,.46-.23,.62-1.97,1.93-4.74,4.71-8.32,8.32,2.86,2.91,5.61,5.67,8.27,8.29,.17,.17,.26,.36,.26,.57,0,.24-.08,.44-.24,.62s-.35,.26-.58,.26c-.24,0-.44-.09-.61-.26-2.65-2.62-5.41-5.38-8.27-8.29-2.57,2.62-5.28,5.42-8.14,8.4-.17,.17-.36,.26-.57,.26-.24,0-.44-.08-.6-.23Z"
                />
              </g>
              <path
                className="t"
                d="M52.29,22.32c-2.92,2.91-5.83,5.82-8.75,8.73-.2,.2-.37,.34-.5,.41-.71,.36-1.54,.19-2.03-.45-.41-.55-.45-1.29-.03-1.85,.09-.13,.22-.27,.37-.42,1-.99,1.99-1.99,2.99-2.98l5.44-5.43,5.87-5.87s0-.02,0-.02h0c-.56-.28-.86-.72-.9-1.33-.02-.3,.05-.57,.2-.82,.31-.5,.76-.75,1.35-.75,.74,0,1.59,0,2.52,0,.1,0,.21,0,.32,0,.72-.03,1.24,.28,1.56,.92,.11,.22,.15,.46,.13,.73-.01,.15-.02,.29-.02,.44,0,.69,0,1.4,0,2.12,0,.25-.02,.45-.08,.6-.15,.42-.41,.72-.8,.9-.7,.32-1.55,.05-1.94-.61,0-.01-.01-.01-.02,0l-5.68,5.68Z"
              />
              <path
                className="t"
                d="M49.03,17.93c-.12,.12-.29,.23-.51,.33-.59,.2-1.11,.12-1.58-.25-.6-.47-.75-1.32-.41-1.98,.07-.13,.26-.36,.57-.67,1.69-1.68,1.96-1.95,3.33-3.32,.46-.46,1.18-.58,1.77-.31,.84,.39,1.19,1.42,.73,2.22-.07,.13-.26,.34-.55,.64-1.39,1.39-1.69,1.67-3.35,3.34Z"
              />
              <path
                className="t"
                d="M53.62,29.11c-1.62,1.62-3.23,3.23-4.85,4.85-.29,.29-.51,.48-.64,.55-.56,.31-1.27,.25-1.76-.16-.61-.52-.8-1.38-.36-2.07,.07-.11,.2-.26,.39-.45,1.66-1.66,3.32-3.32,4.97-4.98,1.31-1.31,2.61-2.61,3.91-3.91,.34-.34,.58-.55,.7-.62,.33-.18,.68-.24,1.04-.17,1.11,.19,1.69,1.5,1.06,2.44-.07,.1-.2,.25-.4,.45-1.35,1.35-2.71,2.71-4.07,4.07Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
