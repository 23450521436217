import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import BannerLogo from "../layout/BannerLogo";
import { useNavigate } from "react-router-dom";

export default function Signup() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [ref, setRef] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  const handleFirstNameChange = (e) => setFirstName(e.target.value);
  const handleLastNameChange = (e) => setLastName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    validatePhoneNumber(e.target.value);
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(number)) {
      setPhoneError("Invalid phone number. Must be 10 digits.");
    } else {
      setPhoneError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (phoneError) {
      setError("Please fix the errors before submitting.");
      return;
    }
    try {
      setLoading(true);
      setError("");
      await signup();
      setShowAlert(true);
    } catch (error) {
      console.error(error.message);
      setError("Failed to create account. Please try again.");
    }
    setLoading(false);
  };

  const handleClose = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  const signup = async () => {
    const body = {
      firstName,
      lastName,
      email,
      phoneNumber,
    };

    const signupUrl = ref
      ? `/v1/auth/signup?signup_token=${ref}`
      : `/v1/auth/signup`;

    const response = await fetch(signupUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error(
        result.error || "Failed to create account. Please try again."
      );
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const refQuery = params.get("ref");
    setRef(refQuery);
  }, []);

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Box sx={{ maxWidth: 400, width: "100%" }}>
        <Card>
          <CardContent>
            <BannerLogo />
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              Sign Up
            </Typography>
            <Typography variant="body2" align="center" gutterBottom>
              Create an Account to Order and Manage Leads
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            {showAlert ? (
              <>
                <Alert severity="success">
                  Your email ({email}) has been registered. You may now login.
                </Alert>
                <Button
                  sx={{ mt: 4, width: "100%" }}
                  variant="outlined"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </>
            ) : (
              <form onSubmit={handleSubmit}>
                <TextField
                  className="mb-4 d-none"
                  label="Who Referred you?"
                  type="text"
                  value={ref}
                  fullWidth
                  disabled
                  sx={{ display: "none" }}
                />
                <TextField
                  className="mb-3"
                  label="First Name"
                  type="text"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  required
                  fullWidth
                  margin="normal"
                />
                <TextField
                  className="mb-3"
                  label="Last Name"
                  type="text"
                  value={lastName}
                  onChange={handleLastNameChange}
                  required
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Phone Number"
                  type="tel"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  required
                  fullWidth
                  margin="normal"
                  error={!!phoneError}
                  helperText={phoneError}
                />
                <Button
                  sx={{ mt: 4, width: "100%" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  Sign up
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
        <Box sx={{ textAlign: "center", mt: 2 }}>
          Already have an account? <a href="/login">Sign In</a>
        </Box>
      </Box>
    </Container>
  );
}
