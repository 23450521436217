import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";
import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const AdAccountManager = () => {
  const currentUser = decode();
  const [tokens, setTokens] = useState([]);
  const [adAccounts, setAdAccounts] = useState([]);
  const [selectedTokenId, setSelectedTokenId] = useState(null);
  const [tokenForm, setTokenForm] = useState({
    type: "facebook",
    token: "",
    expires_at: "",
  });
  const [facebookAdAccounts, setFacebookAdAccounts] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [adAccountForm, setAdAccountForm] = useState({
    id: "",
    ad_account_id: "",
    account_name: "",
    currency: "",
  });

  // Fetch tokens and ad accounts
  useEffect(() => {
    fetchTokens();
    fetchAdAccounts();
  }, []);

  const fetchTokens = async () => {
    try {
      const response = await customFetch(`/v1/partners/${currentUser.partner_id}/tokens`);
      const data = await response.json();
      setTokens(data);
    } catch (error) {
      console.error("Error fetching tokens:", error);
    }
  };

  const fetchAdAccounts = async () => {
    try {
      const response = await customFetch(`/v1/partners/${currentUser.partner_id}/ad_accounts`);
      const data = await response.json();
      setAdAccounts(data);
    } catch (error) {
      console.error("Error fetching ad accounts:", error);
    }
  };

  const handleTokenSubmit = async () => {
    try {
      const method = tokenForm.id ? "PUT" : "POST";
      const url = tokenForm.id
        ? `/v1/partners/${currentUser.partner_id}/tokens/${tokenForm.id}`
        : `/v1/partners/${currentUser.partner_id}/tokens`;
      const response = await customFetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(tokenForm),
      });
      if (response.ok) {
        fetchTokens();
        setTokenForm({ type: "facebook", token: "", expires_at: "" });
      }
    } catch (error) {
      console.error("Error submitting token:", error);
    }
  };

  const handleTokenSelect = async (tokenId) => {
    setSelectedTokenId(tokenId);
    setDialogOpen(true);
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/facebook_ad_accounts/${tokenId}`
      );
      const data = await response.json();
      setFacebookAdAccounts(data);
    } catch (error) {
      console.error("Error fetching Facebook ad accounts:", error);
    }
  };

  const handleAddAdAccount = async (adAccount) => {
    const { id, existing, ...adAccountData } = adAccount; // Exclude id and existing from the request body
    try {
      const response = await customFetch(`/v1/partners/${currentUser.partner_id}/ad_accounts`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...adAccountData, token_id: selectedTokenId }),
      });
      if (response.ok) {
        fetchAdAccounts();
      }
    } catch (error) {
      console.error("Error adding ad account:", error);
    }
  };

  const handleEditAdAccount = async (id, field, value) => {
    try {
      const response = await customFetch(`/v1/partners/${currentUser.partner_id}/ad_accounts/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ [field]: value }),
      });
      if (response.ok) {
        fetchAdAccounts();
      }
    } catch (error) {
      console.error("Error editing ad account:", error);
    }
  };

  const handleDeleteAdAccount = async (id) => {
    try {
      await customFetch(`/v1/partners/${currentUser.partner_id}/ad_accounts/${id}`, {
        method: "DELETE",
      });
      fetchAdAccounts();
    } catch (error) {
      console.error("Error deleting ad account:", error);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setFacebookAdAccounts([]);
  };

  const processRowUpdate = async (newRow) => {
    const { id, account_name, currency } = newRow;
    if (newRow.existing) {
      await handleEditAdAccount(id, "account_name", account_name);
      await handleEditAdAccount(id, "currency", currency);
    }
    return newRow;
  };

  const validateRow = (row) => {
    if (!row.account_name || !row.currency) {
      return false;
    }
    return true;
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4">Ad Account Manager</Typography>

      <Paper sx={{ padding: 2, marginTop: 2 }}>
        <Typography variant="h6">Manage Tokens</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Token"
              value={tokenForm.token}
              onChange={(e) =>
                setTokenForm({ ...tokenForm, token: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              type="datetime-local"
              label="Expires At"
              value={tokenForm.expires_at}
              onChange={(e) =>
                setTokenForm({ ...tokenForm, expires_at: e.target.value })
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Button fullWidth variant="contained" onClick={handleTokenSubmit}>
              {tokenForm.id ? "Update Token" : "Create Token"}
            </Button>
          </Grid>
        </Grid>
        <DataGridPro
          rows={tokens}
          columns={[
            { field: "id", headerName: "ID", flex: 1 },
            { field: "type", headerName: "Type", flex: 1 },
            { field: "token", headerName: "Token", flex: 1 },
            { field: "expires_at", headerName: "Expires At", flex: 1 },
            {
              field: "actions",
              headerName: "Actions",
              flex: 1,
              renderCell: (params) => (
                <Button
                  variant="contained"
                  onClick={() => handleTokenSelect(params.id)}
                >
                  View Ad Accounts
                </Button>
              ),
            },
          ]}
          autoHeight
        />
      </Paper>

      <Paper sx={{ padding: 2, marginTop: 2 }}>
        <Typography variant="h6">Manage Ad Accounts</Typography>
        <DataGridPro
          rows={adAccounts}
          columns={[
            { field: "id", headerName: "ID", flex: 1 },
            { field: "token_id", headerName: "Token ID", flex: 1 },
            { field: "ad_account_id", headerName: "Ad Account ID", flex: 1 },
            {
              field: "account_name",
              headerName: "Account Name",
              flex: 1,
              editable: true,
            },
            {
              field: "currency",
              headerName: "Currency",
              flex: 1,
              editable: true,
            },
            {
              field: "actions",
              headerName: "Actions",
              flex: 1,
              renderCell: (params) => (
                <IconButton
                  onClick={() => handleDeleteAdAccount(params.id)}
                >
                  <DeleteIcon />
                </IconButton>
              ),
            },
          ]}
          autoHeight
          processRowUpdate={(newRow) =>
            validateRow(newRow) ? processRowUpdate(newRow) : null
          }
        />
      </Paper>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Select Facebook Ad Accounts</DialogTitle>
        <DialogContent>
          <DataGridPro
            rows={facebookAdAccounts.map((account) => ({
              ...account,
              existing: adAccounts.some(
                (a) => a.ad_account_id === account.account_id
              ),
            }))}
            columns={[
              { field: "id", headerName: "ID", flex: 1 },
              { field: "account_id", headerName: "Account ID", flex: 1 },
              {
                field: "name",
                headerName: "Account Name",
                flex: 1,
                editable: true,
              },
              {
                field: "currency",
                headerName: "Currency",
                flex: 1,
                editable: true,
              },
              {
                field: "actions",
                headerName: "Actions",
                flex: 1,
                renderCell: (params) =>
                  params.row.existing ? (
                    <>
                      <IconButton
                        onClick={() => processRowUpdate(params.row)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteAdAccount(params.row.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton onClick={() => handleAddAdAccount(params.row)}>
                      <AddIcon />
                    </IconButton>
                  ),
              },
            ]}
            autoHeight
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdAccountManager;
