import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
  Stack,
  Checkbox,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { statesList } from "../../../../general/util/statesList";
import customFetch from "../../../../general/auth/customFetch";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CreateOrder({
  currentUser,
  typesList,
  previousStates,
}) {
  const [product, setProduct] = useState("");
  const [numLeads, setNumLeads] = useState(30);
  const [dailyCap, setDailyCap] = useState(20);
  const [startDate, setStartDate] = useState(null);
  const [states, setStates] = useState([]);
  const [open, setOpen] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");

  const handleClose = () => setOpen(false);
  const handleShow = () => setOpen(true);

  const handleProductChange = (event) => {
    setProduct(event.target.value);
  };

  const handleStatesChange = (event, value) => {
    setStates(value.map((v) => v.value));
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const createOrder = async (e) => {
    e.preventDefault();
    if (!product || numLeads < 30 || dailyCap < 10 || states.length === 0) {
      return;
    }
    try {
      const body = {
        quantity: numLeads,
        product_id: product,
        daily_cap: dailyCap,
        startDate: null,
        states: states,
        location_id: selectedLocation || null, // Set location_id to null if nothing is selected
        successUrl: window.location.href,
      };
      const response = await customFetch(`/v1/orders/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        mode: "cors",
      });
      const data = await response.json();
      window.location = data.redirect;
    } catch (error) {
      console.error(error);
    } finally {
      setProduct("");
      setStates([]);
      setStartDate(null);
      setDailyCap(20);
      setNumLeads(30);
    }
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await customFetch(
          `/v1/users/${currentUser.tenant_user_id}/profile`
        );
        const data = await response.json();
        if (data.locations && data.locations.length > 0) {
          setLocations(data.locations);
          setSelectedLocation(data.primary_location_id);
        }
      } catch (error) {
        console.error("Failed to fetch locations:", error);
      }
    };

    setStates(previousStates || []);
    if (open) {
      fetchLocations();
    }
  }, [open, currentUser.tenant_user_id, previousStates]);

  const isFormValid =
    product && numLeads >= 30 && dailyCap >= 10 && states.length > 0;

  return (
    <div className="content">
      <Button variant="contained" color="primary" onClick={handleShow}>
        Purchase Leads
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle>Purchase Leads</DialogTitle>
        <DialogContent
          sx={{ ".MuiDialogContent-root&.MuiDialogContent-root": { pt: 1 } }}
        >
          <Stack spacing={2}>
            <FormControl fullWidth>
              <TextField
                select
                label="Product"
                value={product}
                onChange={handleProductChange}
                required
                SelectProps={{
                  native: false,
                }}
                error={!product}
                helperText={!product ? "Product is required" : ""}
              >
                {typesList
                  .filter(
                    (type) =>
                      type.product_key === "agent-lead"
                  )
                  .map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>
            {locations.length > 0 && (
              <FormControl fullWidth>
                <TextField
                  select
                  label="GHL Delivery Account"
                  value={selectedLocation}
                  onChange={handleLocationChange}
                  SelectProps={{
                    native: false,
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {locations.map((location) => (
                    <MenuItem key={location.id} value={location.id}>
                      {location.name}{" "}
                      {location.id === selectedLocation ? "(Primary)" : ""}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
            <TextField
              required
              label="Number of Leads"
              type="number"
              onChange={(e) => setNumLeads(parseInt(e.target.value, 10))}
              value={numLeads}
              error={numLeads < 30}
              helperText={numLeads < 30 ? "Minimum Order of 30 Leads" : ""}
              fullWidth
            />
            <TextField
              required
              label="Daily Cap"
              type="number"
              onChange={(e) => setDailyCap(parseInt(e.target.value, 10))}
              value={dailyCap}
              error={dailyCap < 10}
              helperText={
                dailyCap < 10 ? "Minimum Cap of 10 Leads per Day" : ""
              }
              fullWidth
            />
            <Autocomplete
              multiple
              options={statesList}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              value={statesList.filter((state) => states.includes(state.value))}
              onChange={handleStatesChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="States"
                  placeholder="Select states"
                  fullWidth
                  error={states.length === 0}
                  helperText={
                    states.length === 0
                      ? "At least one state must be selected"
                      : ""
                  }
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={createOrder}
            disabled={!isFormValid}
          >
            Proceed to Payment
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
