// App.js
import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./general/layout/GlobalNavbar"; // Import the Layout component
import { CssBaseline } from "@mui/material";

import Dashboard from "./pages/agent/dashboard/Dashboard";
import AgentLeads from "./pages/agent/leads/Leads";
import AgentOrders from "./pages/agent/orders/Orders";
import AgentSupport from "./pages/agent/support/Support";
import AgentFeedback from "./pages/agent/feedback/Feedback";
import AgentSchedules from "./pages/agent/schedules/ScheduleList";
import AdminDashboard from "./pages/admin/dashboard/AdminDashboard";
import AdSetsPage from "./pages/partneradmin/ad_sets/AdSetsPage";
import AdminOrders from "./pages/admin/orders/AdminOrders";
import AdminLeads from "./pages/admin/leads/AdminLeads";
import AdminReplacements from "./pages/admin/replacements/AdminReplacements";
import AdminUsers from "./pages/admin/users/AdminUsers";

import Auth from "./general/auth/Auth";
import Signup from "./general/auth/Signup";
import Login from "./general/auth/Login";
import FAQ from "./pages/agent/faq/FAQ";
import AdminCampaigns from "./pages/partneradmin/campaigns/AdminCampaigns";
import { ThemeProvider } from "@mui/material/styles";
import getTheme from "./theme";
import AdAccountManager from "./pages/partneradmin/ad_accounts/AdAccountManager";
import TenantManagement from "./pages/partneradmin/tenants/TenantManagement";
import PartnerManagement from "./pages/superadmin/partners/PartnerManagement";
import StripeDataPage from "./pages/partneradmin/stripe/StripeDataPage";
import CatalogList from "./pages/partneradmin/product_catalog/CatalogList";
import TenantAdminProductCatalog from "./pages/admin/products/TenantAdminProductCatalog";
import GhlMarketplaceApps from "./pages/partneradmin/ghl/GhlMarketplaceAppsPage";
import AdminGhlUsers from "./pages/admin/ghl/AdminGhlUsers";
import AdminGhlLocations from "./pages/admin/ghl/AdminGhlLocations";
import GroupManagementPage from "./pages/admin/user_groups/GroupManagementPage";
import ProfilePage from "./pages/agent/profile/ProfilePage";
import PartnerAudit from "./pages/partneradmin/audit/PartnerAudit";
import ClaimOverrunLeads from "./pages/agent/overruns/OverrunLeads";
import AdminTenantSchedules from "./pages/admin/schedules/AdminTenantSchedules";
import TenantOverrunsDashboard from "./pages/admin/overruns/tenantOverrunsDashboard";
import AdminTenantUserSpreadsheets from "./pages/admin/spreadsheets/AdminTenantUserSpreadsheets";
import CustomFieldsPage from "./pages/admin/custom_fields/CustomFieldsPage";
import FieldMappingManagementPage from "./pages/admin/custom_fields/FieldMappingManagementPage";
import PartnerUsers from "./pages/partneradmin/partner_users/PartnerUsers";
import SuperUsers from "./pages/superadmin/users/SuperUsers";

function App() {
  const [isAdminMode, setAdminMode] = useState(false);
  const [themeMode, setThemeMode] = useState("light"); // Initial theme mode
  const theme = getTheme(themeMode); // Get the theme based on the current mode

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route
          path="/"
          element={
            <Layout
              setAdminMode={setAdminMode}
              isAdminMode={isAdminMode}
              themeMode={themeMode}
              setThemeMode={setThemeMode}
            />
          }
        >
          <Route path="admin/" element={<AdminDashboard />} />
          <Route path="admin/leads" element={<AdminLeads />} />
          <Route path="admin/orders" element={<AdminOrders />} />
          <Route path="admin/replacements" element={<AdminReplacements />} />
          <Route path="admin/products" element={<TenantAdminProductCatalog />} />
          <Route path="admin/users" element={<AdminUsers />} />
          <Route path="admin/user-groups" element={<GroupManagementPage />} />
          <Route path="admin/user-schedules" element={<AdminTenantSchedules />} />
          <Route path="admin/ghlusers" element={<AdminGhlUsers />} />
          <Route path="admin/ghllocations" element={<AdminGhlLocations />} />
          <Route path="admin/tenant-overruns" element={<TenantOverrunsDashboard />} />
          <Route path="admin/user-spreadsheets" element={<AdminTenantUserSpreadsheets />} />
          <Route path="admin/tenant-custom-fields" element={<CustomFieldsPage />} />
          <Route path="admin/tenant-mapping-groups" element={<FieldMappingManagementPage />} />

          <Route index element={<Dashboard />} />
          <Route path="leads" element={<AgentLeads />} />
          <Route path="schedules" element={<AgentSchedules />} />
          <Route path="orders" element={<AgentOrders />} />
          <Route path="overruns" element={<ClaimOverrunLeads />} />
          <Route path="support" element={<AgentSupport />} />
          <Route path="feedback" element={<AgentFeedback />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="profile" element={<ProfilePage />} />

          <Route path="partner/admin/campaigns" element={<AdminCampaigns />} />
          <Route path="partner/admin/adaccounts" element={<AdAccountManager />} />
          <Route path="partner/admin/adsets" element={<AdSetsPage />} />
          <Route path="partner/admin/tenants" element={<TenantManagement />} />
          <Route path="partner/admin/users" element={<PartnerUsers />} />
          <Route path="partner/admin/stripe" element={<StripeDataPage />} />
          <Route path="partner/admin/productcatalog" element={<CatalogList />} />
          <Route path="partner/admin/ghlmarketplaceapps" element={<GhlMarketplaceApps />} />
          <Route path="partner/admin/audit" element={<PartnerAudit />} />

          <Route path="super/admin/partners" element={<PartnerManagement />} />
          <Route path="super/admin/users" element={<SuperUsers />} />
        </Route>

        <Route path="/auth" element={<Auth />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
