import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

export default function AddGroupMemberDialog({
  open,
  onClose,
  onAddMember,
  groupMembers,
}) {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const currentUser = decode();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await customFetch(
          `/v1/tenantadmin/${currentUser.tenant_id}/tenant-users`,
          { method: "GET" }
        );
        const data = await response.json();
        const filteredUsers = data.filter(
          (user) => !groupMembers.some((member) => member.id === user.id)
        );
        setUsers(filteredUsers);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers();
  }, [groupMembers, currentUser.tenant_id]);

  const handleToggle = (userId) => {
    const currentIndex = selectedUsers.indexOf(userId);
    const newSelectedUsers = [...selectedUsers];

    if (currentIndex === -1) {
      newSelectedUsers.push(userId);
    } else {
      newSelectedUsers.splice(currentIndex, 1);
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handleAdd = () => {
    onAddMember(selectedUsers);
    setSelectedUsers([]);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Group Member</DialogTitle>
      <DialogContent>
        {users.length === 0 ? (
          <Typography>
            No users available to add. All users are already members of this
            group.
          </Typography>
        ) : (
          <List>
            {users.map((user) => (
              <ListItem
                key={user.id}
                button
                onClick={() => handleToggle(user.id)}
              >
                <ListItemText
                  primary={`${user.first_name} ${user.last_name}`}
                  secondary={user.email}
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={() => handleToggle(user.id)}
                    checked={selectedUsers.indexOf(user.id) !== -1}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleAdd}
          color="primary"
          disabled={selectedUsers.length === 0}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
