export const orderStatusList = [
  { value: "pending", label: "Pending" },
  { value: "running", label: "Running" },
  { value: "capped", label: "Capped" },
  { value: "maxed", label: "Daily Max" },
  { value: "fulfilled", label: "Fulfilled" },
  { value: "checkout", label: "Checkout Cart" },
  { value: "created", label: "Created" },
  { value: "paused", label: "Paused" },
  { value: "hold", label: "Hold" },
  // { value: "free", label: "Free" },
];
