import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Chip,
  Box,
} from "@mui/material";
import { useFetch } from "./useFetch";
import decode from "../../../general/util/jwtDecode";

const CatalogForm = ({ open, onClose, catalogItem, onSubmit }) => {
  const currentUser = decode();
  const [formState, setFormState] = useState({
    name: "",
    tenant_id: "",
    campaign_id: "",
    stripe_product_id: "",
    stripe_price_id: "",
    product_type_id: "",
    ad_account_id: "",
    description: "",
    is_active: true,
    crm_tags: [], // Add crm_tags to form state
  });
  const [tagInput, setTagInput] = useState(""); // Input field for new tags

  const { data: tenants } = useFetch(
    `/v1/partners/${currentUser.partner_id}/tenants`
  );
  const { data: campaigns } = useFetch(
    `/v1/partners/${currentUser.partner_id}/campaigns`
  );
  const { data: stripeProducts } = useFetch(
    `/v1/partners/${currentUser.partner_id}/stripe_products`
  );
  const { data: stripePrices } = useFetch(
    `/v1/partners/${currentUser.partner_id}/stripe_prices`
  );
  const { data: productTypes } = useFetch(`/v1/partners/product-types`);
  const { data: adAccounts } = useFetch(
    `/v1/partners/${currentUser.partner_id}/ad_accounts`
  );

  useEffect(() => {
    if (catalogItem) {
      setFormState(catalogItem);
    }
  }, [catalogItem]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "product_type_id") {
      const selectedProductType = productTypes.find(
        (type) => type.id === value
      );
      setFormState({
        ...formState,
        [name]: value,
        product_key: selectedProductType ? selectedProductType.product_key : "",
      });
    } else {
      setFormState({ ...formState, [name]: value });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormState({ ...formState, [name]: checked });
  };

  const handleTagAdd = () => {
    if (tagInput && formState.crm_tags.length < 10) {
      setFormState({
        ...formState,
        crm_tags: [...formState.crm_tags, tagInput],
      });
      setTagInput("");
    }
  };

  const handleTagDelete = (tagToDelete) => {
    setFormState({
      ...formState,
      crm_tags: formState.crm_tags.filter((tag) => tag !== tagToDelete),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formState);
  };

  const selectedStripeProduct = stripeProducts?.find(
    (product) => product.id === formState.stripe_product_id
  );
  const filteredStripePrices = stripePrices?.filter(
    (price) => price.product === selectedStripeProduct?.stripe_id
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {catalogItem?.id ? "Edit Catalog Item" : "Add New Catalog Item"}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            name="name"
            value={formState.name}
            onChange={handleChange}
            fullWidth
            required
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Product Type</InputLabel>
            <Select
              name="product_type_id"
              value={formState.product_type_id}
              onChange={handleChange}
              label="Product Type"
              required
            >
              {productTypes &&
                productTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Tenant</InputLabel>
            <Select
              name="tenant_id"
              label="Tenant"
              value={formState.tenant_id}
              onChange={handleChange}
              required
            >
              {tenants &&
                tenants.map((tenant) => (
                  <MenuItem key={tenant.id} value={tenant.id}>
                    {tenant.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Stripe Product</InputLabel>
            <Select
              name="stripe_product_id"
              value={formState.stripe_product_id}
              onChange={handleChange}
              label="Stripe Product"
              required
            >
              {stripeProducts &&
                stripeProducts.map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.name} ({product.stripe_id})
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Stripe Price</InputLabel>
            <Select
              name="stripe_price_id"
              value={formState.stripe_price_id}
              onChange={handleChange}
              label="Stripe Price"
              required
              disabled={!formState.stripe_product_id}
            >
              {filteredStripePrices &&
                filteredStripePrices.map((price) => (
                  <MenuItem key={price.id} value={price.id}>
                    {price.currency} - {price.unit_amount / 100} (
                    {price.stripe_price_id})
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {formState.product_key === "tenant-ad-account" && (
            <FormControl fullWidth margin="dense">
              <InputLabel>Ad Account</InputLabel>
              <Select
                name="ad_account_id"
                value={formState.ad_account_id}
                onChange={handleChange}
                label="Ad Account"
              >
                {adAccounts &&
                  adAccounts.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.account_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {(formState.product_key === "agent-lead" ||
            formState.product_key === "tenant-bulk-lead" ||
            formState.product_key === "agent-overrun") && (
            <FormControl fullWidth margin="dense">
              <InputLabel>Campaign</InputLabel>
              <Select
                name="campaign_id"
                label="Campaign"
                value={formState.campaign_id}
                onChange={handleChange}
                required
              >
                {campaigns &&
                  campaigns.map((campaign) => (
                    <MenuItem key={campaign.id} value={campaign.id}>
                      {campaign.campaign_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          <TextField
            margin="dense"
            label="Description"
            name="description"
            value={formState.description}
            onChange={handleChange}
            fullWidth
            multiline
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.is_active}
                onChange={handleCheckboxChange}
                name="is_active"
                color="primary"
              />
            }
            label="Active"
          />
          <Box mt={2}>
            <TextField
              label="Add CRM Tag"
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleTagAdd();
                }
              }}
              fullWidth
            />
            <Button
              onClick={handleTagAdd}
              disabled={!tagInput || formState.crm_tags.length >= 10}
              sx={{ mt: 1 }}
            >
              Add Tag
            </Button>
          </Box>
          <Box display="flex" flexWrap="wrap" mt={2}>
            {formState.crm_tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => handleTagDelete(tag)}
                sx={{ margin: 0.5 }}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" color="primary">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CatalogForm;
