import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const TenantManagement = () => {
  const currentUser = decode();
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({ name: "", description: "" });
  const [editingTenant, setEditingTenant] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [tenantToDelete, setTenantToDelete] = useState(null);

  const fetchTenants = async () => {
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/tenants`
      );
      const data = await response.json();
      setTenants(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching tenants:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTenants();
  }, []);

  const handleOpen = (tenant) => {
    if (tenant) {
      setEditingTenant(tenant);
      setForm({ name: tenant.name, description: tenant.description });
    } else {
      setEditingTenant(null);
      setForm({ name: "", description: "" });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setForm({ name: "", description: "" });
    setEditingTenant(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = async () => {
    if (editingTenant) {
      await updateTenant(editingTenant.id, form);
    } else {
      await createTenant(form);
    }
    handleClose();
    fetchTenants();
  };

  const createTenant = async (data) => {
    try {
      await customFetch(`/v1/partners/${currentUser.partner_id}/tenants`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error("Error creating tenant:", error);
    }
  };

  const updateTenant = async (id, data) => {
    try {
      await customFetch(
        `/v1/partners/${currentUser.partner_id}/tenants/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
    } catch (error) {
      console.error("Error updating tenant:", error);
    }
  };

  const confirmDeleteTenant = (id) => {
    setTenantToDelete(id);
    setConfirmOpen(true);
  };

  const handleDeleteClose = () => {
    setConfirmOpen(false);
    setTenantToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    if (tenantToDelete) {
      try {
        await customFetch(
          `/v1/partners/${currentUser.partner_id}/tenants/${tenantToDelete}`,
          {
            method: "DELETE",
          }
        );
        fetchTenants();
      } catch (error) {
        console.error("Error deleting tenant:", error);
      } finally {
        handleDeleteClose();
      }
    }
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1, editable: true },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      editable: true,
    },
    { field: "signup_token", headerName: "Signup Token", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleOpen(params.row)} aria-label="edit">
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => confirmDeleteTenant(params.row.id)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className="content">
      <div style={{ width: "100%" }}>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main", // Use the primary color from the theme
            marginBottom: 2, // Theme spacing unit
            textAlign: "center", // Center align text
          }}
        >
          Tenant Management
        </Typography>
      </div>
      <Box sx={{ height: 600, width: "100%" }}>
        <Button variant="contained" onClick={() => handleOpen(null)}>
          Add Tenant
        </Button>
        <DataGridPro
          rows={tenants}
          columns={columns}
          pageSize={10}
          loading={loading}
          disableSelectionOnClick
        />
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {editingTenant ? "Edit Tenant" : "Add Tenant"}
          </DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={form.name}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="description"
              label="Description"
              type="text"
              fullWidth
              value={form.description}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>
              {editingTenant ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={confirmOpen} onClose={handleDeleteClose}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this tenant?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose}>Cancel</Button>
            <Button onClick={handleDeleteConfirm} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

export default TenantManagement;
