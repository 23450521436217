import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Card,
  CardContent,
  Button,
  IconButton,
  Chip,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch";
import AddGroupMemberDialog from "./components/AddGroupMemberDialog";
import EditGroupDialog from "./components/EditGroupDialog";
import EditProductGroupDialog from "./components/EditProductGroupDialog";
import AddGroupDialog from "./components/AddGroupDialog"; // Import the new AddGroupDialog
import decode from "../../../general/util/jwtDecode";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export default function GroupManagementPage() {
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [members, setMembers] = useState([]);
  const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);
  const [openEditGroupDialog, setOpenEditGroupDialog] = useState(false);
  const [openEditProductGroupDialog, setOpenEditProductGroupDialog] =
    useState(false);
  const [openAddGroupDialog, setOpenAddGroupDialog] = useState(false); // State for the AddGroupDialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for delete confirmation dialog
  const [groupToDelete, setGroupToDelete] = useState(null); // State to hold the group to be deleted
  const currentUser = decode();

  const fetchGroups = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups`,
        { method: "GET" }
      );
      const data = await response.json();
      setGroups(data);
      if (data.length > 0) {
        setSelectedGroup(data[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMembers = async (groupId) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${groupId}/members`,
        { method: "GET" }
      );
      const data = await response.json();
      const membersWithFullName = data.map((member) => ({
        ...member,
        full_name: `${member.first_name} ${member.last_name}`,
      }));

      setMembers(membersWithFullName);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      fetchMembers(selectedGroup.id);
    }
  }, [selectedGroup]);

  const handleTabChange = (event, newValue) => {
    setSelectedGroup(groups[newValue]);
  };

  const handleAddMember = async (memberIds) => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${selectedGroup.id}/members`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user_ids: memberIds }), // Change to user_ids to match the backend
        }
      );
      fetchMembers(selectedGroup.id);
      setOpenAddMemberDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveMember = async (userId) => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${selectedGroup.id}/members/${userId}`,
        {
          method: "DELETE",
        }
      );
      fetchMembers(selectedGroup.id);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditGroup = async (name, description) => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${selectedGroup.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ name, description }),
        }
      );

      fetchGroups();
      setOpenEditGroupDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditProductGroup = async (selectedProducts) => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${selectedGroup.id}/products`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ product_ids: selectedProducts }),
        }
      );

      fetchGroups();
      setOpenEditProductGroupDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddGroup = async (name, description) => {
    try {
      await customFetch(`/v1/tenantadmin/${currentUser.tenant_id}/groups`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, description }),
      });
      fetchGroups();
      setOpenAddGroupDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteGroup = async (groupId) => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${groupId}`,
        {
          method: "DELETE",
        }
      );
      fetchGroups();
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteConfirmation = async () => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${groupToDelete}`,
        {
          method: "DELETE",
        }
      );
      fetchGroups();
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    { field: "full_name", headerName: "Full Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <IconButton onClick={() => handleRemoveMember(params.row.id)}>
          <RemoveCircleOutlineIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Box p={2}>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main", // Use the primary color from the theme
            marginBottom: 2, // Theme spacing unit
            textAlign: "left", // left align text
          }}
        >
          Groups Management
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 2, // Theme spacing unit
            textAlign: "left", // Center align text
          }}
        >
          Groups allow tenant admins to manage and organize users and products.
          Users will only be able to view and purchase products from
          groups where they are a member.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenAddGroupDialog(true)}
        >
          Add New Group
        </Button>
      </Box>
      <Box display="flex" flexGrow={1}>
        <Box flexBasis="20%" display="flex" flexDirection="column">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={
              selectedGroup
                ? groups.findIndex((group) => group.id === selectedGroup.id)
                : 0
            }
            onChange={handleTabChange}
          >
            {groups.map((group, index) => (
              <Tab
                key={group.id}
                label={
                  <Card
                    variant="outlined"
                    sx={{ width: "100%", textAlign: "left", boxShadow: 2 }}
                  >
                    <CardContent>
                      <Typography variant="h6">{group.name}</Typography>
                      <Typography variant="body2">
                        Members: {group.member_count}
                      </Typography>
                      <Typography variant="body2">
                        Products: {group.products.length}
                      </Typography>
                    </CardContent>
                  </Card>
                }
                sx={{ minWidth: "100%", textAlign: "left" }}
              />
            ))}
          </Tabs>
        </Box>
        <Box flexGrow={1} p={1.5}>
          {selectedGroup && (
            <>
              <Card sx={{ boxShadow: 2 }} variant="outlined">
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Box>
                      <Typography variant="h5">{selectedGroup.name}</Typography>
                      <Typography variant="body1">
                        {selectedGroup.description}
                      </Typography>
                      <Box mt={2}>
                        <Typography variant="h6">Assigned Products:</Typography>
                        {selectedGroup.products.length > 0 ? (
                          selectedGroup.products.map((product) => (
                            <Chip
                              key={product.id}
                              label={product.name}
                              color="primary"
                              variant="outlined"
                              sx={{ marginRight: 0.5, marginBottom: 0.5 }}
                            />
                          ))
                        ) : (
                          <Typography variant="body2">
                            No assigned products
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="flex-end" mt="auto">
                      <Tooltip title="Edit Group">
                        <IconButton
                          onClick={() => setOpenEditGroupDialog(true)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Assign Products">
                        <IconButton
                          onClick={() => setOpenEditProductGroupDialog(true)}
                        >
                          <AssignmentIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Group">
                        <IconButton
                          onClick={() => {
                            setGroupToDelete(selectedGroup.id);
                            setOpenDeleteDialog(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenAddMemberDialog(true)}
                >
                  Add Group Member
                </Button>
              </Box>
              <Box mt={2}>
                <DataGridPro
                  rows={members}
                  columns={columns}
                  pageSize={15}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 15 },
                    },
                  }}
                  pageSizeOptions={[15, 50, 100]}
                  pagination
                  autoHeight
                />
              </Box>
            </>
          )}
        </Box>
        <AddGroupMemberDialog
          open={openAddMemberDialog}
          onClose={() => setOpenAddMemberDialog(false)}
          onAddMember={handleAddMember}
          groupMembers={members} // Pass the current group members
        />
        <EditGroupDialog
          open={openEditGroupDialog}
          onClose={() => setOpenEditGroupDialog(false)}
          group={selectedGroup}
          onEditGroup={handleEditGroup}
        />
        <EditProductGroupDialog
          open={openEditProductGroupDialog}
          onClose={() => setOpenEditProductGroupDialog(false)}
          group={selectedGroup}
          onEditProductGroup={handleEditProductGroup}
        />
        <AddGroupDialog
          open={openAddGroupDialog}
          onClose={() => setOpenAddGroupDialog(false)}
          onAddGroup={handleAddGroup}
        />
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this group?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmation} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
