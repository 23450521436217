import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Tooltip,
  Box,
} from "@mui/material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import EditOrder from "./components/EditOrder";
import DeleteOrder from "./components/DeleteOrder";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";
import CustomNoRowsOverlay from "../../../general/layout/CustomNoRowsOverlay";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CreateProductOrder from "./components/CreateProductOrder";

export default function AdminOrders() {
  const currentUser = decode();
  const [orders, setOrders] = useState([]);
  const [tenantUsersList, setTenantUsersList] = useState([]);
  const [productCatalog, setProductCatalog] = useState([]);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  const applyRunningFilter = () => {
    setFilterModel({
      items: [
        {
          field: "order_status",
          operator: "equals",
          value: "running",
        },
      ],
    });
  };

  function CustomToolbar({ applyRunningFilter }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <Button
          onClick={applyRunningFilter}
          startIcon={<PlayCircleOutlineIcon />}
          color="primary"
        >
          Running Only
        </Button>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    if (currentUser && currentUser.role !== "Tenant Admin") {
      window.location = "/login";
      return;
    }
    await Promise.all([fetchTenantUsersAsync()]);
    await fetchProductCatalogAndOrders();
  };

  const fetchTenantUsersAsync = async () => {
    const response = await customFetch(
      `/v1/tenantadmin/${currentUser.tenant_id}/tenant-users`
    );
    const list = await response.json();
    setTenantUsersList(list);
  };

  const fetchProductCatalogAndOrders = async () => {
    const productCatalogResponse = await customFetch(
      `/v1/tenantadmin/${currentUser.tenant_id}/product-catalog`,
      { method: "GET" }
    );
    const productCatalogData = await productCatalogResponse.json();
    setProductCatalog(productCatalogData);

    const ordersResponse = await customFetch(
      `/v1/tenantadmin/${currentUser.tenant_id}/orders`,
      { method: "GET" }
    );
    const ordersData = await ordersResponse.json();
    setOrders(
      ordersData.map((order) => {
        const product = productCatalogData.find(
          (p) => p.id === order.product_id
        );
        return {
          ...order,
          id: order.id,
          userName: `${order.first_name} ${order.last_name}`,
          formattedOrderDate: order.order_date
            ? new Date(order.order_date)
            : null,
          formattedStartDate: order.start_date
            ? new Date(order.start_date)
            : null,
          leadCount: `${order.lead_count - order.replacement_count} / ${
            order.num_leads
          } (after ${order.replacement_count} replaced)`,
          dailyCount: `${order.daily_count} / ${order.daily_cap}`,
          productName: product ? product.name : null,
        };
      })
    );
  };

  const updateOrderInState = (updatedOrder) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === updatedOrder.id ? { ...order, ...updatedOrder } : order
      )
    );
  };

  const addOrderToState = (newOrder) => {
    const product = productCatalog.find((p) => p.id === newOrder.product_id);
    const transformedOrder = {
      ...newOrder,
      userName: `${newOrder.first_name} ${newOrder.last_name}`,
      formattedOrderDate: newOrder.order_date
        ? new Date(newOrder.order_date)
        : null,
      formattedStartDate: newOrder.start_date
        ? new Date(newOrder.start_date)
        : null,
      leadCount: `${newOrder.lead_count - newOrder.replacement_count} / ${
        newOrder.num_leads
      } (after ${newOrder.replacement_count} replaced)`,
      dailyCount: `${newOrder.daily_count} / ${newOrder.daily_cap}`,
      productName: product ? product.name : null,
    };
    setOrders((prevOrders) => [transformedOrder, ...prevOrders]);
  };

  const removeOrderFromState = (orderId) => {
    setOrders((prevOrders) =>
      prevOrders.filter((order) => order.id !== orderId)
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "Batch ID",
      align: "left",
      headerAlign: "left",
      type: "number",
      flex: 0.6,
      renderCell: (params) => <div>{params.value}</div>,
    },
    { field: "userName", headerName: "User Name", flex: 1.2 },
    {
      field: "formattedOrderDate",
      headerName: "Order Date",
      flex: 0.8,
      type: "date",
    },
    {
      field: "refTypeOrProductName",
      headerName: "Product",
      flex: 1,
      renderCell: (params) => {
        return params.row.productName;
      },
    },
    {
      field: "formattedStartDate",
      headerName: "Start Date",
      flex: 0.8,
      type: "date",
    },
    { field: "order_status", headerName: "Status", flex: 0.8 },
    {
      field: "leadCount",
      headerName: "Number of Leads",
      flex: 1.4,
      sortable: false,
      filterable: false,
    },
    {
      field: "dailyCount",
      headerName: "Daily Count",
      flex: 0.7,
      sortable: false,
      filterable: false,
    },
    {
      field: "states",
      headerName: "States",
      width: 110,
      renderCell: (params) => {
        const row = params.row;
        const orderStates = row.states.join(", ");
        const stateLabel = row.states.length === 1 ? "state" : "states";
        return (
          <Tooltip title={orderStates} placement="left">
            <Button size="small" variant="outlined">
              {`${row.states.length} ${stateLabel}`}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <EditOrder
              order={params.row}
              tenantUsersList={tenantUsersList}
              updateOrderInState={updateOrderInState}
            />
            <span> </span>
            <DeleteOrder
              order={params.row}
              removeOrderFromState={removeOrderFromState}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="content">
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main", // Use the primary color from the theme
          marginBottom: 2, // Theme spacing unit
          textAlign: "center", // Center align text
        }}
      >
        Admin Orders
      </Typography>
      <Box display="flex" justifyContent="space-between" margin={2}>
        <Box marginLeft={2}>
          <CreateProductOrder
            tenantUsersList={tenantUsersList}
            updateOrders={addOrderToState}
          />
        </Box>
      </Box>
      <br></br>
      <br></br>
      <div style={{ height: "700px", width: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DataGridPro
            rows={orders}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50]}
            pagination
            autoHeight
            disableSelectionOnClick
            headerFilters
            slots={{
              toolbar: () => (
                <CustomToolbar applyRunningFilter={applyRunningFilter} />
              ),
              headerFilterMenu: null,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            filterModel={filterModel}
            onFilterModelChange={(model) => setFilterModel(model)}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
