import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Alert,
  MenuItem,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { statesList } from "../../../../general/util/statesList";
import { orderStatusList } from "../../../../general/util/orderStatusList";
import Autocomplete from "@mui/material/Autocomplete";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

export default function CreateProductOrder({ tenantUsersList, updateOrders }) {
  const [tenantUserId, setTenantUserId] = useState("");
  const [ghlUserId, setGhlUserId] = useState("");
  const [numLeads, setNumLeads] = useState(30);
  const [dailyCap, setDailyCap] = useState(20);
  const [status, setStatus] = useState("pending");
  const [startDate, setStartDate] = useState(null);
  const [orderDate, setOrderDate] = useState(new Date());
  const [states, setStates] = useState([]);
  const [show, setShow] = useState(false);
  const [typesList, setTypesList] = useState([]);
  const [product, setProduct] = useState("");
  const [ghlLocations, setGhlLocations] = useState([]);
  const [ghlLocationId, setGhlLocationId] = useState(null);
  const currentUser = decode();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleProductChange = (event) => {
    setProduct(event.target.value);
  };

  useEffect(() => {
    const fetchTypesAsync = async () => {
      const typesList = await customFetch(`/v1/tenantadmin/${currentUser.tenant_id}/product-catalog`, {
        method: "GET",
      }).then((res) => res.json());
      setTypesList(typesList);
    };
    fetchTypesAsync();
  }, []);

  const fetchGhlUserAndLocations = async (ghlUserId) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/ghl-users/${ghlUserId}`
      );
      const ghlUser = await response.json();

      const locationsPromises = ghlUser.roles.locationIds.map((locationId) =>
        customFetch(`/v1/tenantadmin/${currentUser.tenant_id}/ghl-locations/ghl/${locationId}`)
      );

      const locationsResponses = await Promise.all(locationsPromises);
      const locationsData = await Promise.all(
        locationsResponses.map((res) => res.json())
      );

      const formattedLocations = locationsData.map((location) => ({
        ...location,
        label:
          location.name +
          (location.ghl_location_id === ghlUser.primary_location_id
            ? " (Primary)"
            : ""),
      }));

      setGhlLocations(formattedLocations);
    } catch (error) {
      console.error("Failed to fetch GHL user and locations:", error);
    }
  };

  const createOrder = async (e) => {
    e.preventDefault();
    try {
      let body = {
        tenant_user_id: tenantUserId,
        product_id: product,
        num_leads: numLeads,
        daily_cap: dailyCap,
        order_status: status,
        order_date: orderDate,
        start_date: startDate,
        states: states,
        ghl_location_id: ghlLocationId,
      };
      const response = await customFetch(`/v1/tenantadmin/${currentUser.tenant_id}/orders`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      const newOrder = await response.json();
      updateOrders(newOrder);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (tenantUserId) {
      const selectedTenantUser = tenantUsersList.find(
        (user) => user.id === tenantUserId
      );
      if (selectedTenantUser && selectedTenantUser.ghl_user_id) {
        setGhlUserId(selectedTenantUser.ghl_user_id);
        fetchGhlUserAndLocations(selectedTenantUser.ghl_user_id);
      } else {
        setGhlUserId("");
        setGhlLocations([]);
      }
    }
  }, [tenantUserId, tenantUsersList]);

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleShow}>
        Create New Order
      </Button>

      <Dialog open={show} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Create a New Order</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            id="create-order"
            noValidate
            autoComplete="off"
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Autocomplete
              options={tenantUsersList}
              getOptionLabel={(option) =>
                `${option.id} - ${option.first_name} ${option.last_name} (${option.email})`
              }
              value={
                tenantUsersList.find((option) => option.id === tenantUserId) ||
                null
              }
              onChange={(e, newValue) =>
                setTenantUserId(newValue ? newValue.id : "")
              }
              renderInput={(params) => (
                <TextField {...params} label="User" margin="dense" required />
              )}
            />
            {ghlUserId && (
              <Autocomplete
                options={ghlLocations}
                getOptionLabel={(option) => option.label}
                value={
                  ghlLocations.find(
                    (location) => location.id === ghlLocationId
                  ) || null
                }
                onChange={(event, newValue) => {
                  setGhlLocationId(newValue ? newValue.id : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="GHL Sub Account"
                    placeholder="Select an account..."
                    fullWidth
                    margin="normal"
                    sx={{
                      marginTop: 0, // Remove any top margin
                      marginBottom: 0, // Remove any bottom margin
                    }}
                  />
                )}
              />
            )}
            <TextField
              select
              label="Product"
              value={product}
              onChange={handleProductChange}
              required
              SelectProps={{
                native: false,
              }}
              error={!product}
              helperText={!product ? "Product is required" : ""}
            >
              {typesList
                .filter((type) => type.product_type_id !== "free")
                .map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              label="Number of Leads"
              type="number"
              required
              value={numLeads}
              onChange={(e) => setNumLeads(e.target.value)}
              inputProps={{ min: 0 }}
            />
            <TextField
              label="Daily Cap"
              type="number"
              required
              value={dailyCap}
              onChange={(e) => setDailyCap(e.target.value)}
              inputProps={{ min: 0 }}
            />
            {dailyCap < 10 && (
              <Alert severity="warning">
                Minimum of 10 leads per day... Are you sure you want to continue
                with {dailyCap} leads per day?
              </Alert>
            )}
            <Autocomplete
              options={orderStatusList}
              getOptionLabel={(option) => option.label}
              value={
                orderStatusList.find((option) => option.value === status) ||
                null
              }
              onChange={(e, newValue) =>
                setStatus(newValue ? newValue.value : "")
              }
              renderInput={(params) => (
                <TextField {...params} label="Order Status" required />
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Order Date"
                inputFormat="MM/dd/yyyy"
                value={orderDate}
                onChange={setOrderDate}
                renderInput={(params) => <TextField {...params} required />}
              />
              <DesktopDatePicker
                label="Start Date"
                inputFormat="MM/dd/yyyy"
                value={startDate}
                onChange={setStartDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Autocomplete
              multiple
              options={statesList}
              getOptionLabel={(option) => option.label}
              value={states.map((state) =>
                statesList.find((s) => s.value === state)
              )}
              onChange={(e, newValue) =>
                setStates(newValue.map((item) => item.value))
              }
              renderInput={(params) => <TextField {...params} label="States" />}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button onClick={createOrder} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
