import React, { useState, useEffect } from "react";
import { Container, Typography, Button, Snackbar } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import decode from "../../../general/util/jwtDecode";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const AdminGhlLocations = () => {
  const [locations, setLocations] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSyncing, setIsSyncing] = useState(false);
  const currentUser = decode();

  useEffect(() => {
    loadLocations();
  }, []);

  const loadLocations = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/ghl-locations`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch locations");
      }
      const data = await response.json();
      // Combine first name and last name into account_owner
      const locationsWithOwner = data.map((location) => ({
        ...location,
        account_owner: `${location.first_name} ${location.last_name}`,
      }));
      setLocations(locationsWithOwner);
    } catch (error) {
      console.error("Failed to load locations:", error);
    }
  };

  const handleSync = async (locationId) => {
    setIsSyncing(true);
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/ghl-locations/${locationId}/syncCustomFields`,
        { method: "POST" }
      );
      if (!response.ok) {
        throw new Error("Failed to sync custom fields");
      }
      setSnackbarMessage("Custom fields synced successfully");
    } catch (error) {
      console.error("Sync failed:", error);
      setSnackbarMessage("Failed to sync custom fields");
    } finally {
      setIsSyncing(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarMessage("");
  };

  const columns = [
    { field: "name", headerName: "Account Name", flex: 1.2 },
    { field: "account_owner", headerName: "Account Owner", flex: 1 },
    { field: "email", headerName: "Email", flex: 1.2 },
    { field: "timezone", headerName: "Timezone", flex: 1 },
    {
      field: "sync",
      headerName: "Sync Custom Fields",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSync(params.row.id)}
          disabled={isSyncing}
        >
          Sync
        </Button>
      ),
    },
  ];

  return (
    <Container>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main", // Use the primary color from the theme
          marginBottom: 2, // Theme spacing unit
          textAlign: "left", // left align text
        }}
      >
        Go High Level - Agency Sub Accounts
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: 2, // Theme spacing unit
          textAlign: "left", // Center align text
        }}
      >
        Sub-accounts, formerly GHL Locations, are sub-accounts which have been
        synced from your agency account after adding the business in a box
        marketplace application. These accounts are used to link users and
        contact references within the business in a box application. These
        sub-accounts are edited in Go High Level and are automatically synced
        back to this screen on a schedule.
      </Typography>
      <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
        <DataGridPro
          rows={locations}
          columns={columns}
          pageSize={10}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          headerFilters
          pageSizeOptions={[10, 25, 50]}
          pagination
          autoHeight
        />
      </div>
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default AdminGhlLocations;
