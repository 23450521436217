import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
} from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch";
import CampaignCard from "./components/CampaignCard";
import decode from "../../../general/util/jwtDecode";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export default function AdminCampaigns() {
  const [campaigns, setCampaigns] = useState([]);
  const currentUser = decode();

  useEffect(() => {
    // Fetch the campaigns data from the API
    customFetch(`/v1/partners/${currentUser.partner_id}/campaigns`)
      .then((response) => response.json())
      .then(async (data) => {
        // Fetch schedules for each campaign
        const campaignsWithSchedules = await Promise.all(
          data.map(async (campaign) => {
            const scheduleResponse = await customFetch(`/v1/partners/${currentUser.partner_id}/campaigns/${campaign.id}/schedules`);
            const schedules = await scheduleResponse.json();
            return { ...campaign, schedule: schedules.length > 0 ? schedules[0] : null };
          })
        );
        setCampaigns(campaignsWithSchedules);
      })
      .catch((error) => console.error("Error fetching campaigns:", error));
  }, []);

  const handleEdit = (id, updatedCampaign) => {
    // Update the campaign in the state
    setCampaigns((prevCampaigns) =>
      prevCampaigns.map((campaign) =>
        campaign.id === id ? { ...campaign, ...updatedCampaign } : campaign
      )
    );

    // Optionally, send the update to the server
    customFetch(`/v1/partners/${currentUser.partner_id}/campaigns/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedCampaign),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      })
      .catch((error) => console.error('Error updating campaign:', error));
  };

  return (
    <div className="content">
      <div style={{ width: "100%" }}>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main", // Use the primary color from the theme
            marginBottom: 2, // Theme spacing unit
            textAlign: "center", // Center align text
          }}
        >
          Campaigns
        </Typography>
        <Box>
          {campaigns.map((campaign) => (
            <CampaignCard key={campaign.id} campaign={campaign} onEdit={handleEdit} bgColor="background.paper" />
          ))}
        </Box>
      </div>
    </div>
  );
}
