import customFetch from "../auth/customFetch";
import decode from "./jwtDecode";

export const nextProductOrderUp = async (state, product_id) => {
  // console.log("Calling next up");
  const currentUser = decode();
  try {
    const response = await customFetch(
      `/v1/tenantadmin/${currentUser.tenant_id}/orders/recommend/product/list/${state}/${product_id}`,
      {
        method: "GET",
        headers: {
          
        },
        credentials: "include",
        withCredentials: true,
      }
    );
    const orders = await response.json();
    return orders.map((order) => {
      const remaining = order.num_leads - order.leads_delivered;
      const difSeconds = order.last_lead
        ? (Date.now() - Date.parse(order.last_lead)) / 1000
        : null;
      let timeText = ``;
      let numText = ``;
      if (!difSeconds) {
        timeText = ` - no previous leads on order`;
      } else if (difSeconds <= 1) {
        timeText = ` - 1 second since last lead`;
      } else if (difSeconds < 60 && difSeconds > 1) {
        timeText = ` - ${Math.round(difSeconds)} seconds since last lead`;
      } else if (difSeconds < 120 && difSeconds >= 60) {
        timeText = ` - 1 minute since last lead`;
      } else if (difSeconds < 3600 && difSeconds >= 120) {
        timeText = ` - ${Math.round(difSeconds / 60)} minutes since last lead`;
      } else if (difSeconds < 7200 && difSeconds >= 3600) {
        timeText = ` - 1 hour since last lead`;
      } else if (difSeconds < 86400 && difSeconds >= 7200) {
        timeText = ` - ${Math.round(
          difSeconds / 60 / 60
        )} hours since last lead`;
      } else if (difSeconds < 172800 && difSeconds >= 86400) {
        timeText = ` - 1 day since last lead`;
      } else if (difSeconds >= 172800) {
        timeText = ` - ${Math.round(
          difSeconds / 60 / 60 / 24
        )} days since last lead`;
      }

      if (remaining === orders[0].num_leads) {
        numText = ` - ${remaining} leads remaining`;
      } else if (remaining === 0) {
        numText = ` - no leads remaining. Order fulfilled!`;
      } else if (remaining === 1) {
        numText = ` - 1 lead remaining`;
      } else if (remaining > 1) {
        numText = ` - ${remaining} leads remaining`;
      }
      return {
        value: order.id,
        label: `${order.id} - ${order.first_name} ${order.last_name} (${order.order_status})${timeText}${numText}`,
      };
    });
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const nextProductOrderUpList = async (product_id) => {
  const currentUser = decode();

  try {
    const response = await customFetch(`/v1/tenantadmin/${currentUser.tenant_id}/orders/recommend/product/list/${product_id}`, {
      method: "GET",
      credentials: "include",
      withCredentials: true,
    });
    const results = await response.json();
    const output = results.map((data) => {
      const remaining = data.num_leads - data.leads_delivered;
      const difSeconds = data.last_lead
        ? (Date.now() - Date.parse(data.last_lead)) / 1000
        : null;
      let timeText = ``;
      let numText = ``;
      if (!difSeconds) {
        timeText = ` - no previous leads on order`;
      } else if (difSeconds <= 1) {
        timeText = ` - 1 second since last lead`;
      } else if (difSeconds < 60 && difSeconds > 1) {
        timeText = ` - ${Math.round(difSeconds)} seconds since last lead`;
      } else if (difSeconds < 120 && difSeconds >= 60) {
        timeText = ` - 1 minute since last lead`;
      } else if (difSeconds < 3600 && difSeconds >= 120) {
        timeText = ` - ${Math.round(difSeconds / 60)} minutes since last lead`;
      } else if (difSeconds < 7200 && difSeconds >= 3600) {
        timeText = ` - 1 hour since last lead`;
      } else if (difSeconds < 86400 && difSeconds >= 7200) {
        timeText = ` - ${Math.round(
          difSeconds / 60 / 60
        )} hours since last lead`;
      } else if (difSeconds < 172800 && difSeconds >= 86400) {
        timeText = ` - 1 day since last lead`;
      } else if (difSeconds >= 172800) {
        timeText = ` - ${Math.round(
          difSeconds / 60 / 60 / 24
        )} days since last lead`;
      }

      if (remaining === results[0].num_leads) {
        numText = ` - ${remaining} leads remaining`;
      } else if (remaining === 0) {
        numText = ` - no leads remaining. Order fulfilled!`;
      } else if (remaining === 1) {
        numText = ` - 1 lead remaining`;
      } else if (remaining > 1) {
        numText = ` - ${remaining} leads remaining`;
      }
      return {
        state: data.state,
        type: data.ref_type,
        value: data.id,
        label: `${data.first_name} ${data.last_name} (${data.order_status})${timeText}${numText}`,
      };
    });
    return output;
  } catch (error) {
    console.error(error);
    return [];
  }
};