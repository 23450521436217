// components/OverrunOrdersTable.js

import React, { useState, useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";
import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const ORDER_STATUS_OPTIONS = [
  { value: "created", label: "Created" },
  { value: "checkout", label: "Checkout Cart" },
  { value: "fulfilled", label: "Fulfilled" },
];

const OverrunOrdersTable = ({ orders, onOrderUpdated, onOrderDeleted }) => {
  const currentUser = decode();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [editFormData, setEditFormData] = useState({});
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/tenant-users/`
      );
      if (!response.ok) throw new Error("Failed to fetch users");
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/overruns/orders/${selectedOrder.id}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) throw new Error("Failed to delete order");
      onOrderDeleted();
      setDeleteConfirmOpen(false);
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  const handleEdit = (order) => {
    setSelectedOrder(order);
    setEditFormData({
      tenant_user_id: order.tenant_user_id,
      total_tokens_ordered: order.total_tokens_ordered,
      order_status: order.order_status,
    });
    setEditDialogOpen(true);
  };

  const handleEditSubmit = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/overruns/orders/${selectedOrder.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(editFormData),
        }
      );
      if (!response.ok) throw new Error("Failed to update order");
      onOrderUpdated();
      setEditDialogOpen(false);
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const columns = [
    { field: "id", headerName: "Order ID", flex: 0.4 },
    {
      field: "username",
      headerName: "User",
      flex: 1,
      renderCell: (params) =>
        `${params.row.first_name} ${params.row.last_name}`,
    },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "total_tokens_ordered", headerName: "Total Tokens", flex: 1 },
    {
      field: "order_status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) =>
        ORDER_STATUS_OPTIONS.find((option) => option.value === params.value)
          ?.label || params.value,
    },
    {
      field: "created_time",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setSelectedOrder(params.row);
              setDeleteConfirmOpen(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      <DataGridPro
        rows={orders}
        columns={columns}
        pageSize={5}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        headerFilters
        pageSizeOptions={[5, 10, 25]}
        pagination
        autoHeight
        disableSelectionOnClick
      />

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this order?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Edit Order</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={users}
            getOptionLabel={(option) =>
              `${option.id} - ${option.first_name} ${option.last_name} - ${option.email}`
            }
            value={
              users.find((user) => user.id === editFormData.tenant_user_id) ||
              null
            }
            onChange={(event, newValue) => {
              setEditFormData({
                ...editFormData,
                tenant_user_id: newValue ? newValue.id : null,
              });
            }}
            renderInput={(params) => (
              <TextField {...params} label="User" fullWidth margin="normal" />
            )}
          />
          <TextField
            margin="normal"
            name="total_tokens_ordered"
            label="Total Tokens Ordered"
            type="number"
            fullWidth
            value={editFormData.total_tokens_ordered}
            onChange={(e) =>
              setEditFormData({
                ...editFormData,
                total_tokens_ordered: e.target.value,
              })
            }
          />
          <TextField
            select
            margin="normal"
            name="order_status"
            label="Order Status"
            fullWidth
            value={editFormData.order_status}
            onChange={(e) =>
              setEditFormData({ ...editFormData, order_status: e.target.value })
            }
          >
            {ORDER_STATUS_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleEditSubmit}>Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OverrunOrdersTable;
