// components/OverrunClaimsTable.js

import React, { useState, useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  IconButton,
  Chip,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";
import { statesList } from "../../../../general/util/statesList";
import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const OverrunClaimsTable = ({ claims, onClaimUpdated, onClaimDeleted }) => {
  const currentUser = decode();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [editFormData, setEditFormData] = useState({});
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/tenant-users/`
      );
      if (!response.ok) throw new Error("Failed to fetch users");
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/overruns/claims/${selectedClaim.id}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) throw new Error("Failed to delete claim");
      onClaimDeleted();
      setDeleteConfirmOpen(false);
    } catch (error) {
      console.error("Error deleting claim:", error);
    }
  };

  const handleEdit = (claim) => {
    setSelectedClaim(claim);
    setEditFormData({
      order_id: claim.order_id,
      tenant_user_id: claim.tenant_user_id,
      tokens_used: claim.tokens_used,
      states: Array.isArray(claim.states) ? claim.states : [],
    });
    setEditDialogOpen(true);
  };

  const handleEditSubmit = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/overruns/claims/${selectedClaim.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(editFormData),
        }
      );
      if (!response.ok) throw new Error("Failed to update claim");
      onClaimUpdated();
      setEditDialogOpen(false);
    } catch (error) {
      console.error("Error updating claim:", error);
    }
  };

  const renderStates = (params) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {params.value.map((state, index) => (
        <Chip key={index} label={state} size="small" />
      ))}
    </Box>
  );

  const columns = [
    { field: "id", headerName: "Claim ID", flex: 0.4 },
    { field: "order_id", headerName: "Reference Order", flex: 0.8 },
    {
      field: "username",
      headerName: "User",
      flex: 1,
      renderCell: (params) =>
        `${params.row.first_name} ${params.row.last_name}`,
    },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "tokens_used", headerName: "Tokens Used", flex: .7 },
    {
      field: "states",
      headerName: "States",
      flex: 1,
      renderCell: renderStates,
    },
    {
      field: "created_time",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEdit(params.row)} disabled="true">
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setSelectedClaim(params.row);
              setDeleteConfirmOpen(true);
            }}
            disabled="true"
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      <DataGridPro
        rows={claims}
        columns={columns}
        pageSize={5}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        headerFilters
        pageSizeOptions={[5, 10, 25]}
        pagination
        autoHeight
        disableSelectionOnClick
      />

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this claim?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Edit Claim</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            name="order_id"
            label="Order ID"
            type="number"
            fullWidth
            value={editFormData.order_id}
            onChange={(e) =>
              setEditFormData({ ...editFormData, order_id: e.target.value })
            }
          />
          <Autocomplete
            options={users}
            getOptionLabel={(option) =>
              `${option.id} - ${option.first_name} ${option.last_name} - ${option.email}`
            }
            value={
              users.find((user) => user.id === editFormData.tenant_user_id) ||
              null
            }
            onChange={(event, newValue) => {
              setEditFormData({
                ...editFormData,
                tenant_user_id: newValue ? newValue.id : null,
              });
            }}
            renderInput={(params) => (
              <TextField {...params} label="User" fullWidth margin="normal" />
            )}
          />
          <TextField
            margin="normal"
            name="tokens_used"
            label="Tokens Used"
            type="number"
            fullWidth
            value={editFormData.tokens_used}
            onChange={(e) =>
              setEditFormData({ ...editFormData, tokens_used: e.target.value })
            }
          />
          <Autocomplete
            multiple
            options={statesList}
            getOptionLabel={(option) => option.label}
            value={
              editFormData.states
                ? statesList.filter((state) =>
                    editFormData.states.includes(state.value)
                  )
                : []
            }
            onChange={(event, newValue) => {
              setEditFormData({
                ...editFormData,
                states: newValue.map((item) => item.value),
              });
            }}
            renderInput={(params) => (
              <TextField {...params} label="States" fullWidth margin="normal" />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  key={index}
                  label={option.value}
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleEditSubmit}>Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OverrunClaimsTable;
