// components/CreateOrderButton.js

import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

const ORDER_STATUS_OPTIONS = [
  { value: "created", label: "Created" },
  { value: "checkout", label: "Checkout Cart" },
  { value: "fulfilled", label: "Fulfilled" },
];

const CreateOrderButton = ({ onOrderCreated }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    tenant_user_id: "",
    total_tokens_ordered: "",
    order_status: "",
  });
  const [users, setUsers] = useState([]);
  const currentUser = decode();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/tenant-users/`
      );
      if (!response.ok) throw new Error("Failed to fetch users");
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/overruns/orders`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );
      if (!response.ok) throw new Error("Failed to create order");
      onOrderCreated();
      handleClose();
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Create New Order
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New Overrun Order</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={users}
            getOptionLabel={(option) =>
              `${option.id} - ${option.first_name} ${option.last_name} - ${option.email}`
            }
            onChange={(event, newValue) => {
              setFormData({
                ...formData,
                tenant_user_id: newValue ? newValue.id : null,
              });
            }}
            renderInput={(params) => (
              <TextField {...params} label="User" fullWidth margin="normal" />
            )}
          />
          <TextField
            margin="normal"
            name="total_tokens_ordered"
            label="Total Tokens Ordered"
            type="number"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
          <TextField
            select
            margin="normal"
            name="order_status"
            label="Order Status"
            fullWidth
            variant="outlined"
            value={formData.order_status}
            onChange={handleChange}
          >
            {ORDER_STATUS_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Create</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateOrderButton;
