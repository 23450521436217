// components/CreateClaimButton.js

import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  Chip,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";
import { statesList } from "../../../../general/util/statesList";

const CreateClaimButton = ({ onClaimCreated }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    tenant_user_id: "",
    leads_request: {
      "0-2days": 0,
      "2days-7days": 0,
      "7days-30days": 0,
      "30days-90days": 0,
      "90days+": 0,
    },
    states: [],
    overrun_product_id: "",
  });
  const [users, setUsers] = useState([]);
  const [overrunProducts, setOverrunProducts] = useState([]);
  const [error, setError] = useState("");
  const currentUser = decode();

  useEffect(() => {
    fetchUsers();
    fetchOverrunProducts();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/tenant-users/`
      );
      if (!response.ok) throw new Error("Failed to fetch users");
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchOverrunProducts = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/product-catalog`
      );
      const data = await response.json();
      const overrunProducts = data.filter(
        (product) => product.product_key === "agent-overrun"
      );
      setOverrunProducts(overrunProducts);
      if (overrunProducts.length > 0) {
        setFormData((prevState) => ({
          ...prevState,
          overrun_product_id: overrunProducts[0].id,
        }));
      }
    } catch (error) {
      console.error("Error fetching overrun products", error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setError("");
  };

  const handleLeadsRequestChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      leads_request: {
        ...prevState.leads_request,
        [name]: parseInt(value, 10),
      },
    }));
  };

  const handleStatesChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormData((prevState) => ({
      ...prevState,
      states: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/overruns/claims`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to create claim");
      }
      onClaimCreated();
      handleClose();
    } catch (error) {
      console.error("Error creating claim:", error);
      setError(error.message);
    }
  };

  const tokenCosts = {
    "0-2days": 5,
    "2days-7days": 4,
    "7days-30days": 3,
    "30days-90days": 2,
    "90days+": 1,
  };

  const totalTokens = Object.entries(formData.leads_request).reduce(
    (acc, [key, value]) => acc + (tokenCosts[key] || 0) * value,
    0
  );

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Create New Claim
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Create New Overrun Claim</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={users}
            getOptionLabel={(option) =>
              `${option.id} - ${option.first_name} ${option.last_name} - ${option.email}`
            }
            onChange={(event, newValue) => {
              setFormData({
                ...formData,
                tenant_user_id: newValue ? newValue.id : null,
              });
            }}
            renderInput={(params) => (
              <TextField {...params} label="User" fullWidth margin="normal" />
            )}
          />
          <TextField
            select
            margin="dense"
            label="Overrun Product"
            fullWidth
            value={formData.overrun_product_id}
            onChange={(e) =>
              setFormData({ ...formData, overrun_product_id: e.target.value })
            }
          >
            {overrunProducts.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </TextField>
          {Object.keys(formData.leads_request).map((key) => (
            <TextField
              key={key}
              margin="dense"
              label={`${key} Leads`}
              type="number"
              fullWidth
              name={key}
              value={formData.leads_request[key]}
              onChange={handleLeadsRequestChange}
            />
          ))}
          <FormControl fullWidth margin="dense">
            <InputLabel>States</InputLabel>
            <Select
              multiple
              label="States"
              value={formData.states}
              onChange={handleStatesChange}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {statesList.map((state) => (
                <MenuItem key={state.value} value={state.value}>
                  <Checkbox
                    checked={formData.states.indexOf(state.value) > -1}
                  />
                  <ListItemText primary={state.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Total Tokens Used: {totalTokens}
          </Typography>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Create Claim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateClaimButton;
